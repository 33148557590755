import React, { useEffect, useState } from "react";

type CarouselProps = {
  components: React.ReactNode[]; // Array of React components or elements
  intervalToChange?: number; // Optional number of milliseconds to change the component
  visibleCount?: number; // Optional number of components to display at once
  direction?: "row" | "column"; // Optional direction of the components
  gap?: string; // Optional gap between the components
};

const Carousel: React.FC<CarouselProps> = ({
  components,
  intervalToChange = 2000, //default to 2 seconds
  visibleCount = 1, // Default to displaying 1 component at a time
  direction = "row", // Default direction is row
  gap = "0px", // Default gap is 0px
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % components.length);
    }, intervalToChange); // Change every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [components.length, intervalToChange]);

  const getVisibleComponents = () => {
    const visibleComponents = [];
    for (let i = 0; i < visibleCount; i++) {
      const index = (currentIndex + i) % components.length;
      visibleComponents.push(components[index]);
    }
    return visibleComponents;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: direction,
        justifyContent: "center",
        gap: gap,
        flex: 1,
      }}
    >
      {getVisibleComponents().map((component, index) => (
        <div key={index}>{component}</div>
      ))}
    </div>
  );
};

export default Carousel;
