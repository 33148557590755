import { useReducer } from "react";
import { ApplicationUser, UserSite } from "../types/user";
import { createCtx } from "../utilities/contextHelper";
import { dummyMcMullensUser, dummyUsers } from "../data/dummyUser";
import { createAction } from "../utilities/createReducerAction";

type UserState = {
  user?: ApplicationUser;
};

enum UserStateEvents {
  USER_CHANGE,
}

const userChangeEvent = (userId: string) =>
  createAction(UserStateEvents.USER_CHANGE, userId);

type UserChangeEvent = ReturnType<typeof userChangeEvent>;

type UserStateActions = UserChangeEvent;

const initialUserState: UserState = {
  user: dummyMcMullensUser,
};

const userReducer = (state: UserState, action: UserStateActions): UserState => {
  switch (action.type) {
    case UserStateEvents.USER_CHANGE:
      return {
        ...state,
        user: dummyUsers.find((u) => u.userId === action.payload),
      };
    default:
      throw new Error(`Unknown action type: ${action}`);
  }
};

interface UserContext {
  //organisation?: Organisation;
  userState: UserState;
  userChangeOnUserid: (user: string) => void;
}

export const [useUserContext, CtxProvider] = createCtx<UserContext>();

type UserProviderProps = {
  children: React.ReactNode;
};
export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const [userState, dispatchUserState] = useReducer(
    userReducer,
    initialUserState
  );
  // const siteIdSearchParam = searchParams.get(UrlSearchParams.SITE_ID);

  const userChangeOnUserid = (user: string) => {
    dispatchUserState(userChangeEvent(user));
  };

  // oidc stuff goes in here so we encapsulate all the oidc logic and actions in one place
  // also load the user and organisation data here from the db and persist in context and encode in local storage to its retained

  return (
    <CtxProvider value={{ userState, userChangeOnUserid }}>
      {children}
    </CtxProvider>
  );
};
