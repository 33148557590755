import { FC } from "react";
import "./dailyProductMetrics.css";
import { DonutChartLined } from "@tag/tag-icons";
import { useForecastContext } from "../../../../context/forecastContext";
import { useTheme } from "../../../../context/themeContext";
import SkeletonLoader from "../../../SkeletonLoader";
import ErrorMessage from "../../../errorMessage/errorMessage";

type DailyProductMetricsProps = {};

const DailyProductMetrics: FC<DailyProductMetricsProps> = () => {
  const { theme } = useTheme();
  const { forecastState } = useForecastContext();

  if (forecastState.forecastData.loading) {
    return <SkeletonLoader />;
  }
  if (forecastState.forecastData.error) {
    return <ErrorMessage dataType="Forecast" fontSize="13px" />;
  }
  return (
    <div className="daily-product-metrics-container">
      <div className="daily-product-metrics-header">
        <div className="daily-product-metrics-header-title">
          <span>
            <DonutChartLined accent="teal" /> {"Product Usage Metrics"}
          </span>
        </div>
      </div>
      <div className="daily-product-metrics-content-container"></div>
    </div>
  );
};

export default DailyProductMetrics;
