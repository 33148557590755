import ViewHeader from "../../../viewHeader/viewHeader";
import { LineChartSolid2Filled } from "@tag/tag-icons";
import DishForecastTable from "./dishSalesTable/dishForecastTable";
import { useState } from "react";
import ForecastTableFilters from "./forecastTableFilters/forecastTableFilters";
import ProductUsageTable from "./productUsageTable/productUsageTable";
import { useForecastContext } from "../../../../context/forecastContext";
import SkeletonLoader from "../../../SkeletonLoader";
import ErrorMessage from "../../../errorMessage/errorMessage";
import "./forecastTable.css";

export type ForecastTableType = "dishSales" | "productUsage";

const ForecastTable: React.FC = () => {
  const { forecastState, setModalVisible, manualForecastChangeEvent } =
    useForecastContext();
  const [selectedTable, setSelectedTable] =
    useState<ForecastTableType>("dishSales");

  if (forecastState.forecastData.loading) {
    return <SkeletonLoader />;
  }
  if (forecastState.forecastData.error) {
    return <ErrorMessage dataType="Forecast" fontSize="13px" />;
  }

  return (
    <div className="forecast-table-container">
      <ViewHeader
        icon={<LineChartSolid2Filled accent="teal" />}
        title={
          selectedTable === "dishSales"
            ? "Dish Sales Forecast"
            : "Product Usage Forecast"
        }
        filters={
          <ForecastTableFilters
            value={selectedTable}
            onToggleChange={setSelectedTable}
            onSave={setModalVisible}
            onClear={() => manualForecastChangeEvent(undefined)}
          />
        }
      />
      <div className="forecast-sales-table">
        {selectedTable === "dishSales" && <DishForecastTable />}
        {selectedTable === "productUsage" && <ProductUsageTable />}
      </div>
    </div>
  );
};

export default ForecastTable;
