import React, { useState, useEffect, FC } from "react";

type DynamicSvgLoaderProps = {
  iconName: string;
};

const DynamicSvgLoader: FC<DynamicSvgLoaderProps> = ({ iconName }) => {
  const [Icon, setIcon] = useState(null);

  useEffect(() => {
    const importSvg = async () => {
      try {
        const importedIcon = await import(
          `../assets/weatherIcons/${iconName}.svg`
        );
        setIcon(importedIcon.default); // `.default` for webpack setups
      } catch (error) {
        console.error(`Failed to load SVG: ${iconName}`, error);
        setIcon(null); // Handle case where SVG is not found
      }
    };

    importSvg();
  }, [iconName]);

  return (
    <div>
      {Icon ? (
        <img src={Icon} alt={iconName} />
      ) : (
        <p>Icon not found: {iconName}</p>
      )}
    </div>
  );
};

export default DynamicSvgLoader;
