import React, { useState } from "react";
import dayjs from "dayjs";
import "./monthDay.css";
import { DailyForecast } from "../../../types/weatherForecast";
import { PublicHoliday } from "../../../types/publicHoliday";

type CalendarDayProps = {
  day: dayjs.Dayjs;
  events: { date: string; title: string }[];
  onAddEvent: (event: { date: string; title: string }) => void;
  weatherForecast?: DailyForecast;
  publicHolidays?: PublicHoliday[];
};

const MonthDay: React.FC<CalendarDayProps> = ({
  day,
  events,
  onAddEvent,
  weatherForecast,
  publicHolidays,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [eventTitle, setEventTitle] = useState("");

  const handleAddEvent = () => {
    if (eventTitle.trim()) {
      onAddEvent({ date: day.format("YYYY-MM-DD"), title: eventTitle });
      setEventTitle("");
      setShowModal(false);
    }
  };

  return (
    <div className="month-calendar-day">
      <div className="day-header">{day.date()}</div>
      <ul className="month-event-list">
        {events.map((event, index) => (
          <li key={index}>{event.title}</li>
        ))}
      </ul>
      <button onClick={() => setShowModal(true)}>Add Forecast</button>

      {showModal && (
        <div className="month-modal">
          <input
            type="text"
            value={eventTitle}
            onChange={(e) => setEventTitle(e.target.value)}
            placeholder="Event Title"
          />
          <button onClick={handleAddEvent}>Add</button>
          <button onClick={() => setShowModal(false)}>Cancel</button>
        </div>
      )}
    </div>
  );
};

export default MonthDay;
