import {
  WeatherSunLined,
  MoonLined,
  ThermometerFilled,
  ThermometerLined,
  ArrowBottomRightFilled,
  DropFilled,
  WeatherCloudLined,
  DropLined,
} from "@tag/tag-icons";
import DailyWeatherStatistic from "./dailyWeatherStatistic";
import { DailyForecast } from "../../../types/weatherForecast";

const weatherStatisticsArray = (forecast: DailyForecast) => {
  const statsArray: React.ReactNode[] = [
    <DailyWeatherStatistic
      metrics={[forecast.sunrise]}
      title="Sunrise"
      icon={<WeatherSunLined accent="teal" />}
    />,
    <DailyWeatherStatistic
      metrics={[forecast.sunset]}
      title="Sunset"
      icon={<MoonLined accent="teal" />}
    />,
    <DailyWeatherStatistic
      metrics={[
        `Min: ${forecast.minTemperature}`,
        `Max: ${forecast.maxTemperature}`,
      ]}
      icon={<ThermometerFilled accent="teal" />}
      title="Temperature"
    />,
    <DailyWeatherStatistic
      metrics={[
        `Min: ${forecast.feelsLikeMinTemp}`,
        `Max: ${forecast.feelLikemaxTemp}`,
      ]}
      icon={<ThermometerLined accent="teal" />}
      title="Feels Like"
    />,
    <DailyWeatherStatistic
      metrics={[forecast.windSpeed]}
      title="Wind"
      icon={<ArrowBottomRightFilled accent="teal" />}
    />,
    <DailyWeatherStatistic
      metrics={[forecast.precipitationChance]}
      title="Precip Chance"
      icon={<DropFilled accent="teal" />}
    />,
    <DailyWeatherStatistic
      metrics={[forecast.precipitationCover]}
      title="Precip Cover"
      icon={<DropLined accent="teal" />}
    />,
    <DailyWeatherStatistic
      metrics={[forecast.cloudCover]}
      title="Cloud Cover"
      icon={<WeatherCloudLined accent="teal" />}
    />,
  ];
  return statsArray;
};

export default weatherStatisticsArray;
