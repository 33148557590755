import React from "react";
import dayjs from "dayjs";
import { useForecastContext } from "../../../context/forecastContext";
import "./weekGrid.css";
import WeekDay from "./weekDay/weekDay";

const WeekGrid: React.FC = () => {
  const { forecastState } = useForecastContext();
  // Generate the days of the week based on the starting date
  const getWeekDays = (start: dayjs.Dayjs) => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(start.add(i, "day"));
    }
    return days;
  };
  const weekDays = getWeekDays(dayjs(forecastState.searchPeriod.from));
  const weekDayComponents: JSX.Element[] = [];
  weekDays.forEach((day, index) => {
    weekDayComponents.push(<WeekDay key={index} day={day} />);
  });

  return (
    <>
      <div className="weekly-calendar-metrics"></div>
      <div className="weekly-calendar-grid">
        {weekDays.map((day, index) => (
          <div key={index} className="weekly-calendar-day-header-cell">
            {day.format("dddd Do MMM")}
          </div>
        ))}

        {weekDayComponents}
      </div>
    </>
  );
};

export default WeekGrid;
