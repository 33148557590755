import {
  LeftNavigation,
  LeftNavigationHeader,
  LeftNavigationBody,
  LeftNavigationItem,
  LeftNavigationItemContent,
} from "@tag/tag-components-react-v4";
import {
  HomeLined,
  DonutChartLined,
  LineChartSolid2Filled,
} from "@tag/tag-icons";
import { FC } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import "./sideNavigation.css";
import { useTheme } from "../../context/themeContext";
import dayjs from "dayjs";
import { useUserContext } from "../../context/userContext";
import { PeriodTypes } from "../../constants/periodTypes";
import UrlSearchParams from "../../constants/urlSearchParams";

const SideNavigation: FC = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { theme } = useTheme();
  const { userState } = useUserContext();
  const selectedSiteId =
    searchParams.get(UrlSearchParams.SITE_ID) ??
    userState.user?.siteAccess[0].siteId;

  return (
    <div className="side-navigation-container">
      <LeftNavigation
        style={{
          height: "100%",
          backgroundColor: "var(--secondary-background-color)",
          borderRight: "1px solid var(--border-color)",
        }}
        accent="teal"
        selectedItem={location.pathname}
        background={theme === "dark" ? "dark" : "light"}
      >
        <LeftNavigationHeader>Menu</LeftNavigationHeader>
        <LeftNavigationBody>
          <LeftNavigationItem name="/">
            <Link to={"/"} style={{ textDecoration: "none", width: "100%" }}>
              <LeftNavigationItemContent
                name={"/"}
                icon={<HomeLined />}
                text="Home"
              />
            </Link>
          </LeftNavigationItem>

          <LeftNavigationItem name="/sales-mix-analysis">
            <Link
              to={`sales-mix-analysis?${UrlSearchParams.PERIOD_TYPE}=${
                PeriodTypes.DAILY
              }&${UrlSearchParams.DATE}=${dayjs().format("YYYY-MM-DD")}&${
                UrlSearchParams.SITE_ID
              }=${selectedSiteId}`}
              style={{ textDecoration: "none", width: "100%" }}
            >
              <LeftNavigationItemContent
                icon={<DonutChartLined />}
                text="Sales-Mix Analysis"
                name="/sales-mix-analysis"
              />
            </Link>
          </LeftNavigationItem>

          <LeftNavigationItem name={"/forecasting"}>
            <Link
              to={`forecasting?${UrlSearchParams.PERIOD_TYPE}=${
                PeriodTypes.DAILY
              }&${UrlSearchParams.DATE}=${dayjs().format("YYYY-MM-DD")}&${
                UrlSearchParams.SITE_ID
              }=${selectedSiteId}`}
              style={{ textDecoration: "none", width: "100%" }}
            >
              <LeftNavigationItemContent
                icon={<LineChartSolid2Filled />}
                text="Forecasting"
                name="/forecasting"
              />
            </Link>
          </LeftNavigationItem>
        </LeftNavigationBody>
      </LeftNavigation>
    </div>
  );
};

export default SideNavigation;
