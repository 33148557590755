import { FC, useMemo } from "react";
import { useForecastContext } from "../../../../../context/forecastContext";
import { DataTable, Format, Span } from "@tag/tag-components-react-v4";
import { flattenProductUsage } from "../../../../../utilities/forecastHelpers";

const ProductUsageTable: FC = () => {
  const { forecastState } = useForecastContext();
  const data =
    forecastState.forecastChangeTracking?.updated?.ProductUsage ??
    forecastState.forecastData.data[0]?.ProductUsage ??
    [];
  const columns: any[] = useMemo(
    () => [
      {
        id: "id",
        accessorKey: "Id",
        header: "Product Id",
        meta: { dataType: "string" },
        enableResizing: false,
        size: 100,
      },

      {
        id: "productName",
        accessorKey: "ProductName",
        header: "Product Name",
        meta: { dataType: "string" },
        enableResizing: false,
        size: 100,
        aggregationFn: "unique",
      },
      {
        id: "dishName",
        accessorKey: "DishName",
        header: "Dish",
        meta: { dataType: "string" },
        aggregationFn: "unique",
      },
      {
        id: "recipeName",
        accessorKey: "RecipeName",
        header: "Recipe",
        meta: { dataType: "string" },
        aggregationFn: "unique",
      },
      {
        id: "quantity",
        accessorKey: "Quantity",
        header: "Quantity",
        meta: { dataType: "decimal" },
        aggregationFn: "sum",
        aggregatedCell: ({ getValue }: { getValue: () => number }) => (
          <Span style={{ fontWeight: "700", fontStyle: "italic" }}>
            <Format dataType="decimal" value={getValue()} />
          </Span>
        ),
      },
      {
        id: "unitOfMeasure",
        accessorKey: "UnitOfMeasure",
        header: "Unit of Measure",
        meta: { dataType: "string" },
        aggregationFn: "unique",
      },
    ],
    []
  );

  return (
    <DataTable
      columns={columns}
      data={flattenProductUsage(data)}
      enableGrouping
      enableExpanding
      height={"46vh"}
      initialState={{
        grouping: ["id"],
      }}
    />
  );
};

export default ProductUsageTable;
