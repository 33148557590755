import { FC } from "react";
import ForecastCalendar from "../../components/calendar/forecastCalendar";
import ViewHeader from "../../components/viewHeader/viewHeader";
import { LineChartSolid2Filled } from "@tag/tag-icons";
import { useForecastContext } from "../../context/forecastContext";
import ForecastMixFilters from "../../components/forecastMixFilters/forecastMixFilters";
import { PeriodTypes } from "../../constants/periodTypes";
import { upperCaseFirstLetter } from "../../utilities/stringHelpers";
import { useUserContext } from "../../context/userContext";
import SubmitForecastModal from "../../components/submitForecastModal/submitForecastModal";

const Forecast: FC = () => {
  const {
    forecastState,
    modalVisible,
    dateChangeEvent,
    monthChangeEvent,
    periodTypeChangeEvent,
    siteChangeEvent,
    setModalVisible,
  } = useForecastContext();

  const { userState } = useUserContext();

  return (
    <>
      <ViewHeader
        icon={<LineChartSolid2Filled size="medium" accent="teal" />}
        title={`Forecasting \\ ${upperCaseFirstLetter(
          PeriodTypes[forecastState.periodType]
        )} \\ ${forecastState.selectedSite?.siteName}`}
        filters={
          <ForecastMixFilters
            sites={userState.user!.siteAccess}
            selectedSite={forecastState.selectedSite?.siteId}
            periodType={forecastState.periodType}
            currentDate={forecastState.selectedDate}
            onDateChange={dateChangeEvent}
            onMonthChange={monthChangeEvent}
            onPeriodChange={periodTypeChangeEvent}
            onSiteChange={siteChangeEvent}
          />
        }
      />
      <ForecastCalendar />
      <SubmitForecastModal
        visible={modalVisible}
        setVisible={setModalVisible}
      />
    </>
  );
};

export default Forecast;
