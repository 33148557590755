import { FC } from "react";
import { Pill, Pills, Tooltip } from "@tag/tag-components-react-v4";
import { useTheme } from "../../../../context/themeContext";
import { CalendarUserLined, PlusLined } from "@tag/tag-icons";
import "./dailySiteEvents.css";
import { useForecastContext } from "../../../../context/forecastContext";
import SkeletonLoader from "../../../SkeletonLoader";
import ErrorMessage from "../../../errorMessage/errorMessage";

const DailySiteEvents: FC = () => {
  const { theme } = useTheme();
  const { forecastState } = useForecastContext();

  if (forecastState.siteEvents.loading) {
    return <SkeletonLoader />;
  }
  if (forecastState.siteEvents.error) {
    return <ErrorMessage dataType="Site Events" fontSize="13px" />;
  }

  const siteEvents = forecastState.siteEvents.data;
  return (
    <div className="daily-site-events-container">
      <div className="daily-site-events-header">
        <div className="daily-site-events-header-title">
          <span>
            <CalendarUserLined accent="teal" /> {"Site Events"}
          </span>
        </div>
        <div className="daily-site-events-add-new-icon">
          <Tooltip
            background={theme}
            style={{
              borderRadius: "8px",
              border: "1px solid var(--border-color)",
            }}
            tooltipFor={
              <PlusLined accent="teal" style={{ cursor: "pointer" }} />
            }
          >
            <div className="week-day-site-event-popover-content">
              +Add new site event
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="daily-site-events-content-container">
        {siteEvents.length > 0 ? (
          siteEvents.map((siteEvent, index) => (
            <div key={index} className="daily-site-events-info-content">
              <div className="daily-site-events-info-header">
                {siteEvent.name}
              </div>
              <div className="daily-site-events-info-description">
                {siteEvent.description}
              </div>
              <div className="daily-site-events-info-tags">
                <Pills>
                  {siteEvent.tags.map((tag, index) => (
                    <Pill key={index}>{tag}</Pill>
                  ))}
                </Pills>
              </div>
            </div>
          ))
        ) : (
          /*to-do make a better no events component */ <>No site events</>
        )}
      </div>
    </div>
  );
};

export default DailySiteEvents;
