import { FC } from "react";
import HourlyWeatherForecast from "./hourlyWeather/hourlyWeatherForecast";
import DailyWeatherForecast from "./dailyWeather/dailyWeatherForecast";
import "./dayLayout.css";
import DailySiteEvents from "./dailySiteEvents/dailySiteEvents";
import DailyPublicHolidays from "./dailyPublicHolidays/dailyPublicHolidays";
import DailyForecastRationale from "./dailyForecastRationale/dailyForecastRationale";
import DailyRevenueMetrics from "./dailyRevenueMetrics/dailyRevenueMetrics";
import DailySalesMetrics from "./dailySalesMetrics/dailySalesMetrics";
import DailyProductMetrics from "./dailyProductMetrics/dailyProductMetrics";
import ForecastTable from "./forecastTable.tsx/forecastTable";

const DayLayout: FC = () => {
  return (
    <div className="day-layout-container">
      <div className="day-layout-topsection">
        <div className="day-layout-weather-summary-container">
          <DailyWeatherForecast />
        </div>
        <div className="day-layout-hourly-weather-forecast-container">
          <HourlyWeatherForecast />
        </div>
      </div>
      <div className="day-layout-bottomsection">
        <div className="day-layout-bottomsection-left-container">
          <ForecastTable />
        </div>
        <div className="day-layout-bottomsection-right-container">
          <div className="forecast-metrics-grid-container">
            <div className="forecast-metrics-grid-item">
              <DailyRevenueMetrics />
            </div>
            <div className="forecast-metrics-grid-item">
              <DailyForecastRationale />
            </div>
            <div className="forecast-metrics-grid-item">
              <DailyPublicHolidays />
            </div>
            <div className="forecast-metrics-grid-item">
              <DailySalesMetrics />
            </div>
            <div className="forecast-metrics-grid-item">
              <DailyProductMetrics />
            </div>
            <div className="forecast-metrics-grid-item">
              <DailySiteEvents />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DayLayout;
