import {
  DishSalesForecast,
  FlattenedUsage,
  ManualForecastChange,
  ProductUsage,
} from "../types/dishSalesForecast";

const updateItemsOnManualForecastChange = (
  forecast: DishSalesForecast,
  updatedData: ManualForecastChange
): DishSalesForecast => {
  // Extract the DishId and new QuantitySold from the updated data
  const updatedDishId = updatedData.dish.Mdr.DishId;
  const newQuantitySold = parseInt(updatedData.quantitySold, 10);

  // Create a new Items array with updated QuantitySold and recalculated NetAmount
  const updatedItems = forecast.Items.map((item) => {
    if (item.Mdr.DishId === updatedDishId) {
      // Calculate the cost per unit
      const costPerUnit = item.NetAmount / item.QuantitySold;

      // Recalculate the NetAmount based on the new QuantitySold
      const newNetAmount = costPerUnit * newQuantitySold;

      // Return a new object with the updated QuantitySold and NetAmount
      return {
        ...item,
        QuantitySold: newQuantitySold,
        NetAmount: parseFloat(newNetAmount.toFixed(2)),
      };
    }
    // Return the item as is if it doesn't match
    return item;
  });

  // Return a new DishSalesForecast object with the updated Items array
  return {
    ...forecast,
    Items: updatedItems,
  };
};

const updateDishSalesForecastWithAggregates = (
  forecast: DishSalesForecast
): DishSalesForecast => {
  // Calculate total QuantitySold and NetAmount from Items array
  const totalQuantitySold = forecast.Items.reduce(
    (sum, item) => sum + item.QuantitySold,
    0
  );
  const totalNetAmount = forecast.Items.reduce(
    (sum, item) => sum + item.NetAmount,
    0
  );

  const roundedTotalQuantitySold = parseFloat(totalQuantitySold.toFixed(2));
  const roundedTotalNetAmount = parseFloat(totalNetAmount.toFixed(2));

  // Update the forecast's QuantitySold and NetAmount with the calculated totals
  const updatedForecast = {
    ...forecast,
    QuantitySold: roundedTotalQuantitySold,
    NetAmount: roundedTotalNetAmount,
    Items: forecast.Items.map((item) => {
      // Calculate the new percentages
      const netAmountPercentageInPeriod = (
        (item.NetAmount / roundedTotalNetAmount) *
        100
      ).toFixed(2);
      const quantitySoldPercentageInPeriod = (
        (item.QuantitySold / roundedTotalQuantitySold) *
        100
      ).toFixed(2);

      // Return a new object with updated percentages
      return {
        ...item,
        NetAmountPercentageInPeriod: parseFloat(netAmountPercentageInPeriod),
        QuantitySoldPercentageInPeriod: parseFloat(
          quantitySoldPercentageInPeriod
        ),
      };
    }),
  };

  return updatedForecast;
};

function updateProductUsageOnForecastChange(
  forecast: DishSalesForecast,
  updatedData: ManualForecastChange
): DishSalesForecast {
  const updatedDishId = updatedData.dish.Mdr.DishId;
  const newQuantitySold = parseInt(updatedData.quantitySold, 10);

  // Update ProductUsage array
  const updatedProductUsage = forecast.ProductUsage.map((productUsage) => {
    // Update UsageByDish array for the matched DishId
    const updatedUsageByDish = productUsage.UsageByDish.map((usageByDish) => {
      if (usageByDish.DishId === updatedDishId) {
        // Calculate the new Quantity based on QuanityForOne and new QuantitySold
        const newQuantity = usageByDish.QuanityForOne * newQuantitySold;
        return {
          ...usageByDish,
          Quantity: newQuantity,
        };
      }
      return usageByDish;
    });

    // Calculate new AggregatedDailyUsage by summing the updated Quantity values
    const newAggregatedDailyUsage = updatedUsageByDish.reduce(
      (sum, usage) => sum + usage.Quantity,
      0
    );

    // Return updated ProductUsage object
    return {
      ...productUsage,
      UsageByDish: updatedUsageByDish,
      AggregatedDailyUsage: parseFloat(newAggregatedDailyUsage.toFixed(2)),
    };
  });

  // Return a new DishSalesForecast object with updated ProductUsage
  return {
    ...forecast,
    ProductUsage: updatedProductUsage,
  };
}

const recalculateForecastOnChange = (
  existingForecast: DishSalesForecast,
  updatedData: ManualForecastChange
) => {
  // update item array with the new value for the updated dish
  const forecast = updateItemsOnManualForecastChange(
    existingForecast,
    updatedData
  );

  // recalulate dish sales forecast aggregates & percentages
  const updatedForecast = updateDishSalesForecastWithAggregates(forecast);

  // recalulate product usage forecast - items first then the head
  return updateProductUsageOnForecastChange(updatedForecast, updatedData);
};

const flattenProductUsage = (
  productUsages: ProductUsage[]
): FlattenedUsage[] => {
  return productUsages.flatMap((productUsage) =>
    productUsage.UsageByDish.map((usageByDish) => ({
      ProductName: productUsage.ProductName,
      Id: productUsage.Id,
      UnitOfMeasure: productUsage.UnitOfMeasure,
      DishId: usageByDish.DishId,
      DishName: usageByDish.DishName,
      RecipeName: usageByDish.RecipeName,
      RecipeId: usageByDish.RecipeId,
      Quantity: usageByDish.Quantity,
    }))
  );
};

export { flattenProductUsage, recalculateForecastOnChange };
