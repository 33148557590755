import { DataLoading } from "../types/dataLoading";
import { DailyForecast } from "../types/weatherForecast";
import { WeatherForecastApi } from "../types/weatherForecastApi";
import { isError } from "../utilities/typeguards";
import { mapWeatherApiData } from "../utilities/weatherHelpers";
import { baseFetch } from "./baseFetch";

type WeatherServiceParams = {
  location: string;
  searchPeriod: { from: string; to: string };
};

const fetchWeather = async (
  params: WeatherServiceParams
): Promise<DataLoading<DailyForecast[]>> => {
  const baseUrl =
    "https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/";
  const endpointUrl = `${params.location}/${params.searchPeriod.from}/${params.searchPeriod.to}?unitGroup=uk&key=${process.env.REACT_APP_WEATHER_API_KEY}&include=days,hours,alerts`;

  const response = await baseFetch<WeatherForecastApi>({
    baseUrl: baseUrl,
    endpointUrl: endpointUrl,
    options: { method: "GET" },
  });
  if (isError(response)) {
    return { data: [], loading: false, error: true };
  }

  return { data: mapWeatherApiData(response), loading: false, error: false };
};

export { fetchWeather };
export type { WeatherServiceParams };
