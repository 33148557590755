import { FC } from "react";
import { Pill } from "@tag/tag-components-react-v4";
import { CalendarBlankLined, MapMarkerFilled } from "@tag/tag-icons";
import "./dailyPublicHolidays.css";
import { useForecastContext } from "../../../../context/forecastContext";
import { publicHolidaysForDate } from "../../../../utilities/publicHolidayHelpers";
import dayjs from "dayjs";
import SkeletonLoader from "../../../SkeletonLoader";
import ErrorMessage from "../../../errorMessage/errorMessage";

const DailyPublicHolidays: FC = () => {
  const { forecastState } = useForecastContext();

  if (forecastState.nationalHolidays.loading) {
    return <SkeletonLoader />;
  }
  if (forecastState.nationalHolidays.error) {
    return <ErrorMessage dataType="Public Holidays" fontSize="13px" />;
  }

  const publicHolidays = publicHolidaysForDate(
    forecastState.nationalHolidays.data,
    dayjs(forecastState.selectedDate)
  );

  return (
    <div className="daily-public-holidays-container">
      <div className="daily-public-holidays-header">
        <div className="daily-public-holidays-header-title">
          <span>
            <CalendarBlankLined accent="teal" /> {"Public Holidays"}
          </span>
        </div>
      </div>
      <div className="daily-public-holidays-content-container">
        {publicHolidays && publicHolidays.length > 0 ? (
          publicHolidays.map((holiday, index) => (
            <div key={index} className="daily-public-holidays-info-content">
              <div className="daily-public-holidays-info-header">
                {holiday.localName}
              </div>
              <div className="daily-public-holidays-info-description">
                <div className="daily-public-holidays-info-icon">
                  <MapMarkerFilled accent="teal" />
                </div>
                <div className="daily-public-holidays-info-text">
                  {`${holiday.localName} is a public holiday in the following regions:`}
                </div>
              </div>
              <div className="daily-public-holidays-info-tags">
                {holiday.subDivionCodes && holiday.subDivionCodes.length > 0 ? (
                  holiday.subDivisionNames?.map((item, index) => (
                    <div
                      key={index}
                      className="daily-public-holidays-info-pill"
                    >
                      <Pill>{item}</Pill>
                    </div>
                  ))
                ) : (
                  <Pill>{holiday.countryName}</Pill>
                )}
              </div>
            </div>
          ))
        ) : (
          <>No public holidays</>
        )}
      </div>
    </div>
  );
};

export default DailyPublicHolidays;
