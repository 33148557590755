import { FC } from "react";
import { CalendarBlankLined, MapMarkerFilled } from "@tag/tag-icons";
import { Pill, Pills, Popover } from "@tag/tag-components-react-v4";
import { useTheme } from "../../../../../../context/themeContext";
import "./weekDayPublicHolidays.css";
import { useForecastContext } from "../../../../../../context/forecastContext";
import { publicHolidaysForDate } from "../../../../../../utilities/publicHolidayHelpers";
import dayjs from "dayjs";
import SkeletonLoader from "../../../../../SkeletonLoader";
import ErrorMessage from "../../../../../errorMessage/errorMessage";

type WeekDayPublicHolidaysProps = {
  day: dayjs.Dayjs;
};

const WeekDayPublicHolidays: FC<WeekDayPublicHolidaysProps> = ({ day }) => {
  const { theme } = useTheme();
  const { forecastState } = useForecastContext();

  if (forecastState.nationalHolidays.loading) {
    return <SkeletonLoader />;
  }

  if (forecastState.nationalHolidays.error) {
    return <ErrorMessage dataType="Public Holidays" fontSize="11px" />;
  }

  const publicHolidays = publicHolidaysForDate(
    forecastState.nationalHolidays.data,
    day
  );

  return (
    <div className="week-day-public-holidays-container">
      {publicHolidays.length > 0 ? (
        publicHolidays.map((holiday, index) => (
          <div className="week-day-public-holiday-info-item" key={index}>
            <span>
              <CalendarBlankLined accent="teal" /> {holiday.localName}
            </span>
            <Popover
              popoverFor={
                <div className="week-day-public-holiday-regions">
                  <MapMarkerFilled accent="teal" />
                </div>
              }
              background={theme}
              style={{
                borderRadius: "8px",
                border: "1px solid var(--border-color)",
              }}
            >
              <div className="week-day-public-holidays-region-popover-content">
                <div className="week-day-public-holidays-region-popover-header">
                  Regions
                </div>
                <div className="week-day-public-holidays-region-popover-description">
                  {`${holiday.localName} is a public holiday in the following regions:`}
                </div>

                <div className="week-day-public-holidays-region-popover-pills">
                  {holiday.subDivionCodes &&
                  holiday.subDivionCodes.length > 0 ? (
                    <Pills>
                      {holiday.subDivisionNames?.map((item, index) => (
                        <Pill key={index}>{item}</Pill>
                      ))}
                    </Pills>
                  ) : (
                    <Pills>
                      <Pill>{holiday.countryName}</Pill>
                    </Pills>
                  )}
                </div>
              </div>
            </Popover>
          </div>
        ))
      ) : (
        <div className="week-day-no-public-holidays-container">
          <span>
            <CalendarBlankLined accent="teal" /> {"No public holidays"}
          </span>
        </div>
      )}
    </div>
  );
};

export default WeekDayPublicHolidays;
