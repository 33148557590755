interface IFetchParams {
  endpointUrl: string;
  baseUrl?: string;
  options?: RequestInit;
}

const baseFetch = async <T>(fetchParams: IFetchParams): Promise<Error | T> => {
  try {
    const response = await fetch(
      `${fetchParams.baseUrl ?? process.env.REACT_APP_API_URL}${
        fetchParams.endpointUrl
      }`,
      fetchParams.options
    );

    if (!response.ok) {
      return new Error(
        `HTTP error! Status: ${response.status}: ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    return new Error(`Fetch error: ${error}`);
  }
};

export { baseFetch };
