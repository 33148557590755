import dayjs from "dayjs";
import { DailyForecast, WeatherAlert } from "../types/weatherForecast";
import { WeatherForecastApi } from "../types/weatherForecastApi";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

/**
 * Maps weather API data to an array of daily forecasts.
 *
 * @param data - The weather forecast data from the API.
 * @typedef WeatherForecastApi
 * @returns  An array of daily forecasts DailyForecast[].
 * @typedef DailyForecast[]
 *
 */
const mapWeatherApiData = (data: WeatherForecastApi): DailyForecast[] => {
  return data.days.map((day) => {
    return {
      date: new Date(day.datetime),
      temperature: `${day.temp}°`,
      minTemperature: `${day.tempmin}°`,
      maxTemperature: `${day.tempmax}°`,
      feelsLike: `${day.feelslike}°`,
      feelsLikeMinTemp: `${day.feelslikemin}°`,
      feelLikemaxTemp: `${day.feelslikemax}°`,
      sunrise: day.sunrise,
      sunset: day.sunset,
      precipitationChance: `${day.precipprob}%`,
      precipitationCover: `${day.precipcover}%`,
      precipitationType: day.preciptype?.join(", ") || "None",
      windSpeed: `${day.windspeed} mph`,
      cloudCover: `${day.cloudcover}%`,
      visibility: `${day.visibility} miles`,
      humidity: `${day.humidity}%`,
      uvIndex: day.uvindex,
      conditions: day.conditions,
      description: day.description,
      icon: day.icon,
      hours: day.hours?.map((hour) => {
        return {
          time: dayjs(hour.datetime, "HH:mm:ss").format("hA").toLowerCase(),
          temperature: `${hour.temp}°`,
          feelsLike: `${hour.feelslike}°`,
          conditions: hour.conditions,
          precipitationChance: `${hour.precipprob}%`,
          precipitationType: hour.preciptype?.join(", ") || "None",
          windSpeed: `${hour.windspeed}mph`,
          icon: hour.icon,
        };
      }),
      alerts: data.alerts?.map((alert) => {
        return {
          event: alert.event,
          headline: alert.headline,
          onset: new Date(alert.onset),
          ends: new Date(alert.ends),
          link: alert.link,
          description: alert.description,
        };
      }),
    };
  });
};

/**
 * Finds the weather forecast for a specific date.
 *
 * @param date - The date for which to find the weather forecast, represented as a `dayjs.Dayjs` object.
 * @param forecast - An array of `DailyForecast` objects representing the weather forecast data.
 * @returns The `DailyForecast` object for the specified date, or `undefined` if no forecast is found for that date.
 */
const findWeatherForecatForDate = (
  date: dayjs.Dayjs,
  forecast: DailyForecast[]
): DailyForecast | undefined => {
  return forecast.find(
    (day) => dayjs(day.date).format("YYYY-MM-DD") === date.format("YYYY-MM-DD")
  );
};

/**
 * Filters weather alerts to those that are active on a specific date.
 *
 * @param alerts - An array of weather alerts to filter.
 * @param date - The date to filter the weather alerts by.
 * @returns An array of weather alerts that are active on the specified date.
 */
const filterWeatherAlrertsToDate = (
  alerts: WeatherAlert[],
  date: dayjs.Dayjs
): WeatherAlert[] => {
  return alerts.filter(
    (weatherAlert) =>
      date.isSameOrAfter(dayjs(weatherAlert.onset).startOf("day")) &&
      date.isSameOrBefore(dayjs(weatherAlert.ends).endOf("day"))
  );
};

export {
  mapWeatherApiData,
  findWeatherForecatForDate,
  filterWeatherAlrertsToDate,
};
