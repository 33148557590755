import { DataLoading } from "../types/dataLoading";
import { SiteEvent } from "../types/siteEvent";
import dayjs from "dayjs";

type FetchSiteEventsParams = {
  siteId: string;
  seatchPeriod: { from: string; to: string };
};

// MOCK SERVICE: to be replaced with actual service when the API exists using baseFetch
const fetchSiteEvents = async (
  params: FetchSiteEventsParams
): Promise<DataLoading<SiteEvent[]>> => {
  try {
    const response = await fetch("/siteEvents.json");
    if (!response.ok) {
      return { data: [], loading: false, error: true };
    }
    const data: SiteEvent[] = await response.json();

    const from = new Date(params.seatchPeriod.from);
    const to = new Date(params.seatchPeriod.to);
    const events: SiteEvent[] = data.map((event) => ({
      ...event,
      date: new Date(event.date),
    }));
    const filteredData = events.filter(
      (event) =>
        dayjs(event.date) >= dayjs(from) &&
        dayjs(event.date) <= dayjs(to).endOf("day")
    );

    return { data: filteredData, loading: false, error: false };
  } catch (error) {
    console.error("Error fetching events:", error);
    return { data: [], loading: false, error: true };
  }
};

export { fetchSiteEvents };
export type { FetchSiteEventsParams };
