import { FC } from "react";
import { DonutChartLined } from "@tag/tag-icons";
import "./dailySalesMetrics.css";
import { useForecastContext } from "../../../../context/forecastContext";
import { useTheme } from "../../../../context/themeContext";
import SkeletonLoader from "../../../SkeletonLoader";
import ErrorMessage from "../../../errorMessage/errorMessage";

type DailySalesMetricsProps = {};

const DailySalesMetrics: FC<DailySalesMetricsProps> = () => {
  const { theme } = useTheme();
  const { forecastState } = useForecastContext();

  if (forecastState.forecastData.loading) {
    return <SkeletonLoader />;
  }
  if (forecastState.forecastData.error) {
    return <ErrorMessage dataType="Forecast" fontSize="13px" />;
  }
  return (
    <div className="daily-sales-metrics-container">
      <div className="daily-sales-metrics-header">
        <div className="daily-sales-metrics-header-title">
          <span>
            <DonutChartLined accent="teal" /> {"Sales Metrics"}
          </span>
        </div>
      </div>
      <div className="daily-sales-metrics-content-container"></div>
    </div>
  );
};

export default DailySalesMetrics;
