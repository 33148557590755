import { PeriodTypes } from "../constants/periodTypes";
import { UserSite } from "../types/user";
import { ParamParser } from "./useSearchParamsWithFallback";

/**
 * Parses a string value into a PeriodTypes enum value or returns null if the value is invalid.
 *
 * @param value - The string value to parse. Can be null.
 * @returns The corresponding PeriodTypes enum value if the value is valid, otherwise null.
 */
const parsePeriodTypeParam: ParamParser<PeriodTypes, null> = (
  value: string | null
): PeriodTypes | null => {
  if (!value) {
    return null;
  }
  if (Object.values(PeriodTypes).includes(+value as unknown as PeriodTypes)) {
    return +value as unknown as PeriodTypes;
  }
  return null;
};

/**
 * Parses a date parameter from a string value.
 *
 * @param value - The string value to parse as a date. If the value is null, the function returns null.
 * @returns The parsed Date object if the value is a valid date string, otherwise null.
 */
const parseDateParam: ParamParser<Date, null> = (
  value: string | null
): Date | null => {
  if (!value) return null;
  const date = new Date(value);
  return isNaN(date.getTime()) ? null : date;
};

/**
 * Parses the site ID parameter from a given string value and returns the corresponding UserSite object.
 *
 * @param value - The site ID value as a string or null.
 * @param sites - An array of UserSite objects to search within. Defaults to an empty array.
 * @returns The UserSite object that matches the given site ID value, or null if no match is found or if the value is null or sites array is empty.
 */
const parseSiteIdParam: ParamParser<UserSite, UserSite[]> = (
  value: string | null,
  sites: UserSite[] = []
): UserSite | null => {
  if (!value || !sites.length) {
    return null;
  }
  return sites.find((site) => site.siteId === value) ?? null;
};

export { parsePeriodTypeParam, parseDateParam, parseSiteIdParam };
