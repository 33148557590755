import React from "react";
import dayjs from "dayjs";
import MonthDay from "./monthDay";
import Collapsible from "../../collapsible/collapsible";
import { useForecastContext } from "../../../context/forecastContext";
import { publicHolidaysForDate } from "../../../utilities/publicHolidayHelpers";
import { findWeatherForecatForDate } from "../../../utilities/weatherHelpers";
import "./monthGrid.css";

type MonthGridProps = {
  events: { date: string; title: string }[];
  onAddEvent: (event: { date: string; title: string }) => void;
};

const MonthGrid: React.FC<MonthGridProps> = ({ events, onAddEvent }) => {
  const { forecastState } = useForecastContext();

  const startOfMonth = dayjs(forecastState.selectedDate).startOf("month");
  const endOfMonth = dayjs(forecastState.selectedDate).endOf("month");
  const daysInMonth = endOfMonth.date();

  // Adjust to start the week on Monday
  const startDayOfWeek = (startOfMonth.day() + 6) % 7; // Shift Sunday (0) to the last position
  const days: JSX.Element[] = [];

  // Generate placeholder cells for days before the first day
  for (let i = 0; i < startDayOfWeek; i++) {
    days.push(
      <div key={`empty-${i}`} className="month-calendar-placeholder"></div>
    );
  }

  // Generate days of the month
  for (let i = 1; i <= daysInMonth; i++) {
    const day = startOfMonth.date(i);
    const dayEvents = events.filter(
      (event) => event.date === day.format("YYYY-MM-DD")
    );
    const weatherForecast = findWeatherForecatForDate(
      day,
      forecastState.weatherForecast.data
    );
    const publicHolidays = publicHolidaysForDate(
      forecastState.nationalHolidays.data,
      day
    );

    days.push(
      <MonthDay
        key={day.format("YYYY-MM-DD")}
        day={day}
        events={dayEvents}
        onAddEvent={onAddEvent}
        weatherForecast={weatherForecast}
        publicHolidays={publicHolidays}
      />
    );
  }

  return (
    <>
      <div className="monthly-calendar-metrics">
        <Collapsible
          title="Forecast Metrics"
          children={<div style={{ height: "200px" }}>Content</div>}
        />
      </div>
      <div className="month-calendar-grid">
        {/* Render weekday headers starting from Monday */}
        {[
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ].map((day, index) => (
          <div key={index} className="month-calendar-header-cell">
            {day}
          </div>
        ))}
        {/* Render days and placeholders */}
        {days}
      </div>
    </>
  );
};

export default MonthGrid;
