import { FC } from "react";
import { WeatherAlert } from "../../../types/weatherForecast";
import { Link, Popover } from "@tag/tag-components-react-v4";
import { useTheme } from "../../../context/themeContext";
import { WarningFilled } from "@tag/tag-icons";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

type WeatherAlertProps = {
  alert: WeatherAlert[];
  size: string;
};

const WeatherAlertIcon: FC<WeatherAlertProps> = ({ alert, size }) => {
  const { theme } = useTheme();

  const alertMessages = alert.map((alert, index) => (
    <AlertMessage key={index} alert={alert} />
  ));

  return (
    <Popover
      popoverFor={<WarningFilled accent="teal" size={size} />}
      background={theme}
      style={{
        borderRadius: "8px",
        border: "1px solid var(--border-color)",
      }}
    >
      {alertMessages}
    </Popover>
  );
};

type AlertMessageProps = {
  alert: WeatherAlert;
};
const AlertMessage: FC<AlertMessageProps> = ({ alert }) => {
  return (
    <div className="alert-message">
      <div className="alert-message-title">
        <h4>{alert.event}</h4>
      </div>
      <span>
        {`Onset: ${dayjs(alert.onset).format("MMM Do h:mma")}`}{" "}
        {`Ends: ${dayjs(alert.ends).format("MMM Do h:mma")} `}
      </span>
      <div>{alert.headline}</div>
      <Link href={alert.link} target="_blank" isExternal>
        More details
      </Link>
    </div>
  );
};

export default WeatherAlertIcon;
