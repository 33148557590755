import { Alert } from "@tag/tag-components-react-v4";
import { CSSProperties, FC } from "react";
import "./errorMessage.css";

type ErrorMessageProps = {
  dataType: string;
  fontSize?: string;
};

const ErrorMessage: FC<ErrorMessageProps> = ({ dataType, fontSize = "11" }) => {
  const customStyles = {
    "--custom-font-size": fontSize,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  } as CSSProperties;
  return (
    <div className="error-message-container" style={customStyles}>
      <Alert closeTransition="fade" type="error">
        {`Error loading ${dataType}, refresh the page to try again`}
      </Alert>
    </div>
  );
};

export default ErrorMessage;
