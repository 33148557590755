import dayjs from "dayjs";
import { FC } from "react";
import WeekDayWeather from "./weekDaySections/weekDayWeather/weekDayWeather";
import WeekDayPublicHolidays from "./weekDaySections/weekDayPublicHoliday/weekDayPublicHolidays";
import WeekDaySiteEvents from "./weekDaySections/weekDaySiteEvents/weekDaySiteEvents";
import "./weekDay.css";

type WeekDayProps = {
  day: dayjs.Dayjs;
};

const WeekDay: FC<WeekDayProps> = ({ day }) => {
  return (
    <div className="week-calendar-day">
      <div className="child" style={{ backgroundColor: "#f4a" }}>
        Div 1
      </div>
      <div className="child" style={{ backgroundColor: "#fa4" }}>
        Div 2
      </div>
      <div className="week-day-site-events-summary">
        <WeekDaySiteEvents day={day} />
      </div>
      <div className="week-day-public-holiday-summary">
        <WeekDayPublicHolidays day={day} />
      </div>
      <div className="week-day-weather-summary">
        <WeekDayWeather day={day} />
      </div>
    </div>
  );
};

export default WeekDay;
