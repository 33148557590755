import { Modal } from "@tag/tag-components-react-v4";
import { FC } from "react";

type SubmitForecstModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
};

const SubmitForecastModal: FC<SubmitForecstModalProps> = ({
  visible,
  setVisible,
}) => {
  return (
    <Modal
      visible={visible}
      accent="teal"
      heading="Details"
      onPrimaryButtonClick={() => {
        console.log("Continue clicked");
      }}
      onClose={() => setVisible(false)}
    ></Modal>
  );
};

export default SubmitForecastModal;
