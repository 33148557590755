import React, { useState } from "react";
import "./hourlyWeatherForecast.css";
import DynamicSvgLoader from "../../../dynamicSvgLoader";
import {
  ArrowBottomRightFilled,
  ArrowLeftThinFilled,
  ArrowRightThinFilled,
  DropFilled,
  ThermometerFilled,
} from "@tag/tag-icons";
import { GhostIconButton } from "@tag/tag-components-react-v4";
import { useForecastContext } from "../../../../context/forecastContext";
import SkeletonLoader from "../../../SkeletonLoader";
import ErrorMessage from "../../../errorMessage/errorMessage";

const HourlyWeatherForecast: React.FC = () => {
  const { forecastState } = useForecastContext();
  const [startIndex, setStartIndex] = useState(6);

  if (forecastState.weatherForecast.loading) {
    return <SkeletonLoader />;
  }

  if (forecastState.weatherForecast.error) {
    return <ErrorMessage dataType="Hourly Weather Forecast" fontSize="13px" />;
  }

  const dailyWeather = forecastState.weatherForecast.data[0];

  // to-do - create a proprt no data message - reuse across components
  if (!dailyWeather.hours || !dailyWeather.hours.length) {
    return <div>No hourly forecast data available</div>;
  }

  const itemsPerPage = 6; // Number of items visible at a time
  const endIndex = startIndex + itemsPerPage;

  const visibleWeather = dailyWeather.hours.slice(startIndex, endIndex);

  const handleNext = () => {
    if (endIndex < dailyWeather.hours.length) {
      setStartIndex(startIndex + itemsPerPage);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - itemsPerPage);
    }
  };

  return (
    <div className="hourly-weather-forecast">
      <GhostIconButton
        accent="teal"
        aria-label="handle-next"
        icon={<ArrowLeftThinFilled />}
        onClick={handlePrev}
        disabled={startIndex === 0}
      />
      <div className="hourly-weather-list">
        {visibleWeather.map((weather, index) => (
          <div key={index} className="hourly-weather-item">
            <div className="hourly-weather-item-time">{weather.time}</div>
            <div className="hourly-weather-item-icon">
              <DynamicSvgLoader iconName={weather.icon} />
            </div>
            <div className="hourly-weather-item-temperature">
              <ThermometerFilled accent="teal" /> {weather.temperature}
            </div>
            <div className="hourly-weather-item-condition">
              {weather.conditions}
            </div>
            <div className="hourly-weather-item-precipitation">
              <DropFilled accent="teal" /> {weather.precipitationChance}
            </div>
            <div className="hourly-weather-item-wind">
              <ArrowBottomRightFilled accent="teal" /> {weather.windSpeed}
            </div>
          </div>
        ))}
      </div>
      <GhostIconButton
        accent="teal"
        aria-label="handle-next"
        icon={<ArrowRightThinFilled />}
        onClick={handleNext}
        disabled={endIndex >= dailyWeather.hours.length}
      />
    </div>
  );
};

export default HourlyWeatherForecast;
