import dayjs from "dayjs";
import { PublicHolidayApi } from "../types/publicHolidayApi";
import { isError } from "../utilities/typeguards";
import { baseFetch } from "./baseFetch";
import { PublicHoliday } from "../types/publicHoliday";
import { mapHolidaysFromApi } from "../utilities/publicHolidayHelpers";
import { DataLoading } from "../types/dataLoading";

function getFullYearsBetween(startDate: Date, endDate: Date) {
  const startYear = dayjs(startDate).year();
  const endYear = dayjs(endDate).year();
  const years = [];

  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }

  return years;
}

type NationalHolidayServiceParams = {
  searchPeriod: { from: string; to: string };
  country: string;
};

const fetchNationalHolidays = async (
  params: NationalHolidayServiceParams
): Promise<DataLoading<PublicHoliday[]>> => {
  const baseUrl = `https://date.nager.at/api/v3/PublicHolidays/`;
  const from = new Date(params.searchPeriod.from);
  const to = new Date(params.searchPeriod.to);
  const yearsToRetrieve = getFullYearsBetween(from, to);

  // Create an array of promises for each year to retrieve
  const fetchPromises = yearsToRetrieve.map((year) => {
    const endpointUrl = `${year}/${params.country}`;
    return baseFetch<PublicHolidayApi[]>({
      baseUrl: baseUrl,
      endpointUrl: endpointUrl,
      options: { method: "GET" },
    });
  });

  try {
    // Use Promise.all to fetch data for all years concurrently
    const responses = await Promise.all(fetchPromises);

    // Check for errors in any of the responses
    const allData = [];
    for (const response of responses) {
      if (isError(response)) {
        // If any response has an error, return early with an error status
        return { data: [], loading: false, error: true };
      }
      // Map and merge the data from successful responses
      allData.push(...mapHolidaysFromApi(response));
    }

    return {
      data: allData,
      loading: false,
      error: false,
    };
  } catch (error) {
    // Handle any unexpected errors
    return { data: [], loading: false, error: true };
  }
};

export { fetchNationalHolidays };
export type { NationalHolidayServiceParams };
