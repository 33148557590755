import { FC } from "react";
import "./dailyRevenueMetrics.css";
import { MoneyBagWithCoinsLined } from "@tag/tag-icons";
import { useForecastContext } from "../../../../context/forecastContext";
import { useTheme } from "../../../../context/themeContext";
import SkeletonLoader from "../../../SkeletonLoader";
import ErrorMessage from "../../../errorMessage/errorMessage";

type DailyRevenueMetricsProps = {};

const DailyRevenueMetrics: FC<DailyRevenueMetricsProps> = () => {
  const { theme } = useTheme();
  const { forecastState } = useForecastContext();

  if (forecastState.forecastData.loading) {
    return <SkeletonLoader />;
  }
  if (forecastState.forecastData.error) {
    return <ErrorMessage dataType="Forecast" fontSize="13px" />;
  }
  return (
    <div className="daily-revenue-metrics-container">
      <div className="daily-revenue-metrics-header">
        <div className="daily-revenue-metrics-header-title">
          <span>
            <MoneyBagWithCoinsLined accent="teal" /> {"Reneue Metrics"}
          </span>
        </div>
      </div>
      <div className="daily-revenue-metrics-content-container"></div>
    </div>
  );
};

export default DailyRevenueMetrics;
