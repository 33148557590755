import { useState, useEffect, useRef, useCallback } from "react";

function usePersistentState<T>(
  key: string,
  defaultValue: T
): [T, (value: T | ((val: T) => T)) => void] {
  // Track the current state and initialize from localStorage or defaultValue
  const [state, setState] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  });

  // Use ref to track the previous state to prevent unnecessary renders
  const prevStateRef = useRef<T>(state);

  const setAndPersistState = useCallback(
    (value: T | ((val: T) => T)) => {
      setState((prevState) => {
        // Calculate the new state based on the incoming value
        const newState =
          typeof value === "function"
            ? (value as (val: T) => T)(prevState)
            : prevStateRef.current;

        // Compare the new state with the previous state using shallow comparison
        if (newState !== prevStateRef.current) {
          // Only persist if the state has actually changed
          localStorage.setItem(key, JSON.stringify(newState));

          // Update refs to track the latest state
          prevStateRef.current = newState;
          return newState;
        }

        // If no state change, return the previous state to avoid unnecessary renders
        return prevState;
      });
    },
    [key]
  );

  // Synchronize state with localStorage on component mount
  useEffect(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      const parsedValue = JSON.parse(storedValue);
      // If the stored value doesn't match the current state, update it
      if (JSON.stringify(parsedValue) !== JSON.stringify(state)) {
        setState(parsedValue);
      }
    }
  }, [key, state]);

  return [state, setAndPersistState];
}

export default usePersistentState;
