import { FC, useState } from "react";
import { ArrowDownFilled, ArrowRightFilled } from "@tag/tag-icons";
import "./collapsible.css";

type CollapsibleProps = {
  title: string;
  children: React.ReactNode;
};

const Collapsible: FC<CollapsibleProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="collapsible-container">
      <div className="collapsible-header">
        {isOpen ? (
          <>
            <ArrowDownFilled accent="teal" onClick={() => setIsOpen(false)} />
          </>
        ) : (
          <>
            <ArrowRightFilled accent="teal" onClick={() => setIsOpen(true)} />
          </>
        )}
        <div className="collapsible-title">{title}</div>
      </div>

      {isOpen && <div className="collapsible-content">{children}</div>}
    </div>
  );
};

export default Collapsible;
