import { createCtx } from "../utilities/contextHelper";
import { createAction } from "../utilities/createReducerAction";
import { PeriodTypes } from "../constants/periodTypes";
import {
  adjustMonths,
  adjustDateOnPeriodTypeChange,
  getSearchPeiodV2,
} from "../utilities/dateHelpers";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useUserContext } from "./userContext";
import { fetchWeather } from "../services/weatherService";
import { DailyForecast } from "../types/weatherForecast";
import { fetchNationalHolidays } from "../services/nationalHolidayService";
import { PublicHoliday } from "../types/publicHoliday";
import dayjs from "dayjs";
import UrlSearchParams from "../constants/urlSearchParams";
import { UserSite } from "../types/user";
import {
  parseDateParam,
  parsePeriodTypeParam,
  parseSiteIdParam,
} from "../utilities/urlParamsHelpers";
import { useSearchParamsWithFallback } from "../utilities/useSearchParamsWithFallback";
import { SiteEvent } from "../types/siteEvent";
import { fetchSiteEvents } from "../services/siteEventsService";
import { ForecastDataLoading } from "../types/forecastDataLoading";
import { fetchForecast } from "../services/forecastService";
import {
  DishSalesForecast,
  ManualForecastChange,
} from "../types/dishSalesForecast";
import { DataLoading } from "../types/dataLoading";
import { ChangeTracking } from "../types/changeTracking";
import { recalculateForecastOnChange } from "../utilities/forecastHelpers";

export type ForecastState = {
  selectedSite?: UserSite;
  forecastData: DataLoading<DishSalesForecast[]>;
  periodType: PeriodTypes;
  selectedDate: Date;
  searchPeriod: { from: string; to: string };
  weatherForecast: DataLoading<DailyForecast[]>;
  nationalHolidays: DataLoading<PublicHoliday[]>;
  siteEvents: DataLoading<SiteEvent[]>;
  forecastChangeTracking?: ChangeTracking<
    DishSalesForecast,
    ManualForecastChange
  >;
};

export enum ForecastStateEvents {
  SITE_CHANGE,
  PERIOD_TYPE_CHANGE,
  DATE_CHANGE,
  LOAD_DATA,
  MANUAL_FORECAST_CHANGE,
}

export const siteChangeEvent = (site: UserSite) =>
  createAction(ForecastStateEvents.SITE_CHANGE, site);
export const periodTypeChangeEvent = (periodType: PeriodTypes) =>
  createAction(ForecastStateEvents.PERIOD_TYPE_CHANGE, periodType);
export const dateChangeEvent = (date: Date) =>
  createAction(ForecastStateEvents.DATE_CHANGE, date);
export const dataLoadEvent = (forecastData: ForecastDataLoading) =>
  createAction(ForecastStateEvents.LOAD_DATA, {
    weatherData: forecastData.weatherData,
    siteEventData: forecastData.siteEventData,
    forecastData: forecastData.forecastData,
    publicHolidayData: forecastData.publicHolidayData,
  });
export const manualForecastChangeEvent = (
  change: ManualForecastChange | undefined
) => createAction(ForecastStateEvents.MANUAL_FORECAST_CHANGE, change);

type SiteChangeEvent = ReturnType<typeof siteChangeEvent>;
type PeriodTypeChangeEvent = ReturnType<typeof periodTypeChangeEvent>;
type DateChangeEvent = ReturnType<typeof dateChangeEvent>;
type DataLoadEvent = ReturnType<typeof dataLoadEvent>;
type ManualForecastChangeEvent = ReturnType<typeof manualForecastChangeEvent>;

export type ForecastStateActions =
  | SiteChangeEvent
  | PeriodTypeChangeEvent
  | DateChangeEvent
  | DataLoadEvent
  | ManualForecastChangeEvent;

const initialForecastState: ForecastState = {
  forecastData: { data: [], error: false, loading: false },
  periodType: PeriodTypes.DAILY,
  selectedDate: new Date(),
  searchPeriod: getSearchPeiodV2(new Date(), PeriodTypes.DAILY),
  weatherForecast: { data: [], error: false, loading: true }, // set to true to prevent a cannot find elemenent error in DailyWeatherForecast & HOurlyWeatherForecast - once a no data placeholder is added set back to false
  nationalHolidays: { data: [], error: false, loading: false },
  siteEvents: { data: [], error: false, loading: false },
};

export const forecastReducer = (
  state: ForecastState,
  action: ForecastStateActions
): ForecastState => {
  switch (action.type) {
    case ForecastStateEvents.SITE_CHANGE:
      return {
        ...state,
        selectedSite: action.payload,
        forecastChangeTracking: undefined,
      };
    case ForecastStateEvents.PERIOD_TYPE_CHANGE:
      const updatedDate = adjustDateOnPeriodTypeChange(action.payload);
      return {
        ...state,
        periodType: action.payload,
        selectedDate: updatedDate,
        searchPeriod: getSearchPeiodV2(updatedDate, action.payload),
        forecastChangeTracking: undefined,
      };
    case ForecastStateEvents.DATE_CHANGE:
      return {
        ...state,
        selectedDate: action.payload,
        searchPeriod: getSearchPeiodV2(action.payload, state.periodType),
        forecastChangeTracking: undefined,
      };
    case ForecastStateEvents.LOAD_DATA:
      return {
        ...state,
        weatherForecast: action.payload.weatherData,
        siteEvents: action.payload.siteEventData,
        forecastData: action.payload.forecastData,
        nationalHolidays: action.payload.publicHolidayData,
        forecastChangeTracking: undefined,
      };
    case ForecastStateEvents.MANUAL_FORECAST_CHANGE:
      if (!action.payload) {
        return { ...state, forecastChangeTracking: undefined };
      }
      const forecastChange: ChangeTracking<
        DishSalesForecast,
        ManualForecastChange
      > = !state.forecastChangeTracking
        ? {
            original: state.forecastData.data[0],
            updated: recalculateForecastOnChange(
              state.forecastData.data[0],
              action.payload
            ),
            changes: [action.payload],
          }
        : {
            ...state.forecastChangeTracking,
            updated: recalculateForecastOnChange(
              state.forecastChangeTracking!.updated!,
              action.payload
            ),
            changes: [...state.forecastChangeTracking.changes, action.payload],
          };
      return { ...state, forecastChangeTracking: forecastChange };
    default:
      throw new Error(`Unknown action type: ${action}`);
  }
};

type FoecastProviderProps = {
  children: React.ReactNode;
};

interface ForecastContextType {
  forecastState: ForecastState;
  modalVisible: boolean;
  periodTypeChangeEvent: (periodType: PeriodTypes) => void;
  monthChangeEvent: (offset: number) => void;
  dateChangeEvent: (date: Date) => void;
  siteChangeEvent: (siteId: string) => void;
  manualForecastChangeEvent: (change: ManualForecastChange | undefined) => void;
  setModalVisible: (visible: boolean) => void;
}

export const [useForecastContext, CtxProvider] =
  createCtx<ForecastContextType>();

export const ForecastProvider: React.FC<FoecastProviderProps> = ({
  children,
}) => {
  const { userState } = useUserContext();
  const { params, setSearchParamsifNeeded } = useSearchParamsWithFallback({
    [UrlSearchParams.PERIOD_TYPE]: {
      default: PeriodTypes.DAILY,
      parser: parsePeriodTypeParam,
    },
    [UrlSearchParams.DATE]: {
      default: new Date(Date.now()),
      parser: parseDateParam,
    },
    [UrlSearchParams.SITE_ID]: {
      default: userState.user!.siteAccess[0],
      parser: parseSiteIdParam,
      validation: userState.user!.siteAccess,
    },
  });

  const [forecastState, dispatchForecastState] = useReducer(forecastReducer, {
    ...initialForecastState,
    periodType: params.periodType,
    selectedDate: params.date,
    selectedSite: params.siteId,
    searchPeriod: getSearchPeiodV2(params.date, params.periodType),
  });
  const [modalVisible, setModalVisible] = useState(false);

  // update state functions
  const periodTypeChangeEvent = (periodType: PeriodTypes) => {
    dispatchForecastState({
      type: ForecastStateEvents.PERIOD_TYPE_CHANGE,
      payload: periodType,
    });
  };

  const siteChangeEvent = (siteId: string) => {
    dispatchForecastState({
      type: ForecastStateEvents.SITE_CHANGE,
      payload:
        userState.user!.siteAccess.find((s) => s.siteId === siteId) ??
        userState.user!.siteAccess[0],
    });
  };

  const dateChangeEvent = (date: Date) => {
    dispatchForecastState({
      type: ForecastStateEvents.DATE_CHANGE,
      payload: date,
    });
  };

  const monthChangeEvent = (offset: number) => {
    dispatchForecastState({
      type: ForecastStateEvents.DATE_CHANGE,
      payload: adjustMonths(forecastState.selectedDate!, offset),
    });
  };

  const manualForecastChangeEvent = (
    change: ManualForecastChange | undefined
  ) => {
    dispatchForecastState({
      type: ForecastStateEvents.MANUAL_FORECAST_CHANGE,
      payload: change,
    });
  };

  const loadData = useCallback(async () => {
    dispatchForecastState(
      dataLoadEvent({
        weatherData: { data: [], error: false, loading: true },
        forecastData: { data: [], error: false, loading: true },
        siteEventData: { data: [], error: false, loading: true },
        publicHolidayData: { data: [], error: false, loading: true },
      })
    );
    const weather = await fetchWeather({
      location: forecastState.selectedSite!.postCode,
      searchPeriod: forecastState.searchPeriod,
    });
    const siteEvents = await fetchSiteEvents({
      siteId: forecastState.selectedSite!.siteId,
      seatchPeriod: forecastState.searchPeriod,
    });
    const forecasts = await fetchForecast({
      siteId: forecastState.selectedSite!.siteId,
      seatchPeriod: forecastState.searchPeriod,
    });
    const nationalHolidays = await fetchNationalHolidays({
      searchPeriod: forecastState.searchPeriod,
      country: "GB", // change to relevant iso code once the users context is known
    });

    dispatchForecastState(
      dataLoadEvent({
        forecastData: forecasts,
        weatherData: weather,
        siteEventData: siteEvents,
        publicHolidayData: nationalHolidays,
      })
    );
  }, [forecastState.searchPeriod, forecastState.selectedSite]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // keep state changes sync'd with URL params
  useEffect(() => {
    setSearchParamsifNeeded({
      [UrlSearchParams.PERIOD_TYPE]: forecastState.periodType.toString(),
      [UrlSearchParams.DATE]: dayjs(forecastState.selectedDate).format(
        "YYYY-MM-DD"
      ),
      [UrlSearchParams.SITE_ID]: forecastState.selectedSite?.siteId || "",
    });
  }, [
    forecastState.periodType,
    forecastState.selectedDate,
    forecastState.selectedSite?.siteId,
    setSearchParamsifNeeded,
  ]);

  return (
    <CtxProvider
      value={{
        forecastState,
        modalVisible,
        periodTypeChangeEvent,
        dateChangeEvent,
        monthChangeEvent,
        siteChangeEvent,
        manualForecastChangeEvent,
        setModalVisible,
      }}
    >
      {children}
    </CtxProvider>
  );
};
