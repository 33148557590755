import { CircleInfoLined } from "@tag/tag-icons";
import { FC } from "react";
import "./dailyForecastRationale.css";
import dayjs from "dayjs";
import { useForecastContext } from "../../../../context/forecastContext";
import SkeletonLoader from "../../../SkeletonLoader";
import ErrorMessage from "../../../errorMessage/errorMessage";

const DailyForecastRationale: FC = () => {
  const { forecastState } = useForecastContext();

  if (forecastState.forecastData.loading) {
    return <SkeletonLoader />;
  }
  if (forecastState.forecastData.error) {
    return <ErrorMessage dataType="Forecast" fontSize="13px" />;
  }

  const forecast = forecastState.forecastData.data[0];

  if (!forecast) {
    return <>No Forecast Data to display</>;
  }
  return (
    <div className="daily-forecast-rationale-container">
      <div className="daily-forecast-rationale-header">
        <div className="daily-forecast-rationale-header-title">
          <span>
            <CircleInfoLined accent="teal" /> {"Forecast Rationale"}
          </span>
        </div>
      </div>
      <div className="daily-forecast-rationale-content-container">
        <div className="daily-forecast-rationale-forecastRationale">
          {forecast.ForecastConfiguration.replace(
            "{SITE}",
            forecastState.selectedSite?.siteName || "Unknown Site"
          ).replace("{WEEKDAY}", forecast.WeekDay)}
        </div>
        <div className="daily-forecast-rationale-lastUpdatedBy">
          <div className="daily-forecast-rationale-lastUpdatedBy-label">
            Last Updated by:
          </div>
          <div className="daily-forecast-rationale-lastUpdatedBy-value">
            {forecast.LastUpdatedBy}
          </div>
        </div>
        <div className="daily-forecast-rationale-lastUpdatedDate">
          <div className="daily-forecast-rationale-lastUpdatedDate-label">
            Last updated date:
          </div>
          <div className="daily-forecast-rationale-lastUpdatedDate-value">
            {dayjs(forecast.LastUpdated).format("DD/MM/YYYY")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyForecastRationale;
