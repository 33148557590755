import { FC } from "react";
import DynamicSvgLoader from "../../../dynamicSvgLoader";
import WeatherAlertIcon from "../../weather/weatherAlert";
import weatherStatisticsArray from "../../weather/weatherStatisticArray";
import Carousel from "../../../carousel/carousel";
import { useForecastContext } from "../../../../context/forecastContext";
import "./dailyWeatherForecast.css";
import SkeletonLoader from "../../../SkeletonLoader";
import ErrorMessage from "../../../errorMessage/errorMessage";

const DailyWeatherForecast: FC = () => {
  const { forecastState } = useForecastContext();

  if (forecastState.weatherForecast.loading) {
    return <SkeletonLoader />;
  }
  if (forecastState.weatherForecast.error) {
    return <ErrorMessage dataType="Weather Forecast" fontSize="13px" />;
  }

  const daillyWeatherData = forecastState.weatherForecast.data[0];
  return (
    <div className="daily-forecast-widget">
      <div className="daily-forecast-main-content">
        <div className="daily-forecast-icon">
          <DynamicSvgLoader iconName={daillyWeatherData.icon} />
        </div>
        <div className="daily-forecast-temperature">
          {daillyWeatherData.temperature}
        </div>
        <div className="daily-forecast-conditions">
          <span>{daillyWeatherData.conditions}</span>
          <span>{`Feels Like ${daillyWeatherData.feelsLike}`}</span>
        </div>
        {daillyWeatherData.alerts?.length > 0 && (
          <WeatherAlertIcon
            alert={daillyWeatherData.alerts}
            size="extraLarge"
          />
        )}
      </div>
      <div className="daily-forecast-description">
        {daillyWeatherData.description}
      </div>
      <div className="daily-forecast-footer">
        <Carousel
          components={weatherStatisticsArray(daillyWeatherData)}
          intervalToChange={5000}
          visibleCount={5}
          gap="5px"
        />
      </div>
    </div>
  );
};

export default DailyWeatherForecast;
