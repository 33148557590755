import dayjs from "dayjs";
import { PublicHoliday } from "../types/publicHoliday";
import { PublicHolidayApi } from "../types/publicHolidayApi";

type IsoCode = "GB-ENG" | "GB-NIR" | "GB-SCT" | "GB-WLS";

const isoCodeToNameMap: Record<IsoCode, string> = {
  "GB-ENG": "England",
  "GB-NIR": "Northern Ireland",
  "GB-SCT": "Scotland",
  "GB-WLS": "Wales",
};

function getCountryNameByIsoCode(isoCode: string): string | undefined {
  const displayNames = new Intl.DisplayNames(["en"], { type: "region" });
  return displayNames.of(isoCode.toUpperCase()) ?? "Unknown Country";
}

const mapHolidaysFromApi = (holidays: PublicHolidayApi[]): PublicHoliday[] => {
  return holidays.map((holiday) => ({
    name: holiday.name,
    localName: holiday.localName,
    date: new Date(holiday.date),
    countryCode: holiday.countryCode,
    countryName:
      getCountryNameByIsoCode(holiday.countryCode) ?? "Unknown Country",
    subDivionCodes: holiday.counties ?? undefined,
    subDivisionNames: holiday.counties?.map(
      (county) => isoCodeToNameMap[county as IsoCode]
    ),
  }));
};

const publicHolidaysForDate = (
  holidays: PublicHoliday[],
  datePredicate: dayjs.Dayjs
): PublicHoliday[] => {
  return holidays.filter(
    (holiday) =>
      dayjs(holiday.date).format("YYYY-MM-DD") ===
      datePredicate.format("YYYY-MM-DD")
  );
};

export { mapHolidaysFromApi, publicHolidaysForDate };
