import { FC } from "react";
import { Popover } from "@tag/tag-components-react-v4";
import DynamicSvgLoader from "../../../../../dynamicSvgLoader";
import WeatherAlertIcon from "../../../../weather/weatherAlert";
import weatherStatisticsArray from "../../../../weather/weatherStatisticArray";
import { useTheme } from "../../../../../../context/themeContext";
import Carousel from "../../../../../carousel/carousel";
import "./weekDayWeather.css";
import { useForecastContext } from "../../../../../../context/forecastContext";
import {
  filterWeatherAlrertsToDate,
  findWeatherForecatForDate,
} from "../../../../../../utilities/weatherHelpers";
import dayjs from "dayjs";
import SkeletonLoader from "../../../../../SkeletonLoader";
import ErrorMessage from "../../../../../errorMessage/errorMessage";

type WeekDayWeatherProps = {
  day: dayjs.Dayjs;
};

const WeekDayWeather: FC<WeekDayWeatherProps> = ({ day }) => {
  const { theme } = useTheme();
  const { forecastState } = useForecastContext();

  if (forecastState.weatherForecast.loading) {
    return <SkeletonLoader />;
  }

  if (forecastState.weatherForecast.error) {
    return <ErrorMessage dataType="Weather" fontSize="11px" />;
  }

  const weatherForecast = findWeatherForecatForDate(
    day,
    forecastState.weatherForecast.data
  );
  const weatherAlerts = filterWeatherAlrertsToDate(
    weatherForecast?.alerts ?? [],
    day
  );
  return (
    <div className="weekly-day-weather-container">
      {weatherForecast ? (
        <>
          <Popover
            popoverFor={
              <div className="weekly-day-weather-icon">
                <DynamicSvgLoader iconName={weatherForecast.icon} />
              </div>
            }
            background={theme}
            style={{
              borderRadius: "8px",
              border: "1px solid var(--border-color)",
            }}
          >
            <div className="weekly-day-weather-popover-content">
              {weatherForecast.description
                ? weatherForecast.description
                : weatherForecast.conditions}{" "}
            </div>
          </Popover>
          <div className="weekly-day-weather-statistics">
            <Carousel
              components={weatherStatisticsArray(weatherForecast)}
              intervalToChange={5000}
            />
          </div>
          {weatherAlerts && weatherAlerts.length > 0 && (
            <WeatherAlertIcon alert={weatherAlerts} size="medium" />
          )}
        </>
      ) : (
        <SkeletonLoader />
      )}
    </div>
  );
};

export default WeekDayWeather;
