import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * A type alias for a function that parses a parameter from a string value.
 *
 * @template T - The type of the parsed parameter.
 * @template V - The type of the optional validation parameter.
 *
 * @param value - The string value to be parsed. Can be `null`.
 * @param validation - An optional validation parameter.
 *
 * @returns The parsed parameter of type `T`, or `null` if parsing fails.
 */
type ParamParser<T, V = never> = (
  value: string | null,
  validation?: V
) => T | null;

/**
 * Configuration for a parameter with optional validation and a default value.
 *
 * @template T - The type of the parameter.
 * @template V - The type of the validation, if any.
 *
 * @property parser - A function or object responsible for parsing the parameter.
 * @property validation - Optional validation rules or constraints for the parameter.
 * @property default - The default value for the parameter.
 */
interface ParamConfig<T, V = never> {
  parser: ParamParser<T, V>;
  validation?: V;
  default: T;
}

/**
 * Represents a configuration object for URL search parameters.
 * Each key in the configuration object corresponds to a parameter name,
 * and the value is a `ParamConfig` object that defines the type and options for that parameter.
 *
 * @template T - An object type where each key corresponds to a parameter name and the value is the type of the parameter.
 */
type ParamsConfig<T> = {
  [K in keyof T]: ParamConfig<T[K], any>;
};

/**
 * Represents the result of parsing search parameters with a fallback mechanism.
 *
 * @template T - The type of the parsed search parameters.
 *
 * @property {T} params - The parsed search parameters.
 * @property {(newParams: Record<string, string>) => void} setSearchParamsifNeeded - A function to update the search parameters if needed.
 */
interface ParsedSearchParamsResult<T> {
  params: T;
  setSearchParamsifNeeded: (newParams: Record<string, string>) => void;
}

/**
 * A custom hook that retrieves and parses search parameters from the URL,
 * providing fallback values if the parameters are not present or invalid.
 * It also provides an optimized function to update the search parameters
 * only if they differ from the current values.
 *
 * @template T - A record type representing the expected search parameters.
 * @param {ParamsConfig<T>} config - A configuration object defining the parser,
 * validation, and default value for each search parameter.
 * @returns {ParsedSearchParamsResult<T>} An object containing the parsed search
 * parameters and a function to update the search parameters if needed.
 */
function useSearchParamsWithFallback<T extends Record<string, any>>(
  config: ParamsConfig<T>
): ParsedSearchParamsResult<T> {
  const [searchParams, setSearchParams] = useSearchParams();

  const result = {} as T;

  for (const [paramName, paramConfig] of Object.entries(config)) {
    const rawValue = searchParams.get(paramName);
    const parsedValue = paramConfig.parser(rawValue, paramConfig.validation);
    result[paramName as keyof T] = parsedValue ?? paramConfig.default;
  }

  // Optimized setSearchParams function
  const currentParams = useMemo(
    () => Object.fromEntries(searchParams.entries()),
    [searchParams]
  );

  /**
   * Updates the search parameters if needed by comparing the current parameters
   * with the new parameters. If any parameter value differs, the search parameters
   * are updated with the new values.
   *
   * @param newParams - An object containing the new search parameters as key-value pairs.
   */
  const setSearchParamsifNeeded = useCallback(
    (newParams: Record<string, string>) => {
      let needsUpdate = false;

      for (const key of Object.keys(newParams)) {
        const newValue = newParams[key];
        const currentValue = currentParams[key] || "";

        if (currentValue !== newValue) {
          needsUpdate = true;
          break;
        }
      }

      if (needsUpdate) {
        setSearchParams(newParams);
      }
    },
    [currentParams, setSearchParams]
  );

  return {
    params: result,
    setSearchParamsifNeeded,
  };
}
export { useSearchParamsWithFallback };
export type { ParamParser };
