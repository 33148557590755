import React, { useState } from "react";
import dayjs from "dayjs";
import MonthGrid from "./month/monthGrid";
import { PeriodTypes } from "../../constants/periodTypes";
import WeekGrid from "./week/weekGrid";
import "./forecastCalendar.css";
import DayLayout from "./day/dayLayout";
import { useForecastContext } from "../../context/forecastContext";

export interface Event {
  date: string;
  title: string;
}

const ForecastCalendar: React.FC = () => {
  const { forecastState } = useForecastContext();
  const [events, setEvents] = useState<Event[]>([]); // Stores events for the calendar

  const handleAddEvent = (event: Event) => {
    setEvents((prevEvents) => [...prevEvents, event]);
  };

  return (
    <div className="forecast-calendar-container">
      {forecastState.periodType === PeriodTypes.MONTHLY && (
        <MonthGrid events={events} onAddEvent={handleAddEvent} />
      )}
      {forecastState.periodType === PeriodTypes.WEEKLY && <WeekGrid />}
      {forecastState.periodType === PeriodTypes.DAILY && <DayLayout />}
    </div>
  );
};

export default ForecastCalendar;
