import { FC, useCallback, useMemo } from "react";
import { useForecastContext } from "../../../../../context/forecastContext";
import { DataTable, Format, Input, Span } from "@tag/tag-components-react-v4";
import {
  ExpandedState,
  ManualForecastChange,
} from "../../../../../types/dishSalesForecast";
import useLocalStorageState from "../../../../../utilities/useLocalStorageState";

const DishSalesTable: FC = () => {
  const { forecastState, manualForecastChangeEvent } = useForecastContext();
  const handleManualForecastChange = useCallback(
    (e: ManualForecastChange) => manualForecastChangeEvent(e),
    [manualForecastChangeEvent]
  );
  const [expanded, setExpanded] = useLocalStorageState<ExpandedState>(
    "expandedState",
    {}
  );

  const data =
    forecastState.forecastChangeTracking?.updated?.Items ??
    forecastState.forecastData.data[0]?.Items ??
    [];

  const columns: any[] = useMemo(
    () => [
      {
        id: "DishId",
        accessorKey: "Mdr.DishId",
        header: "Dish Id",
        meta: { dataType: "string" },
        enableResizing: false,
        size: 100,
      },

      {
        id: "DishName",
        accessorKey: "Mdr.DishName",
        header: "Dish Name",
        meta: { dataType: "string" },
        enableResizing: false,
        size: 100,
      },
      {
        id: "Level1Menu",
        accessorKey: "Mdr.Level1MenuName",
        header: "Level 1 Menu",
        meta: { dataType: "string" },
        aggregationFn: "unique",
      },
      {
        id: "Level2Menu",
        accessorKey: "Mdr.Level2MenuName",
        header: "Level 2 Menu",
        meta: { dataType: "string" },
        aggregationFn: "unique",
      },
      {
        id: "Level3Menu",
        accessorKey: "Mdr.Level3MenuName",
        header: "Level 3 Menu",
        meta: { dataType: "string" },
        aggregationFn: "unique",
      },
      {
        id: "NetAmount",
        accessorKey: "NetAmount",
        header: "Revenue",
        meta: { dataType: "currency" },
        aggregationFn: "sum",
        aggregatedCell: ({ getValue }: { getValue: () => number }) => (
          <Span style={{ fontWeight: "700", fontStyle: "italic" }}>
            <Format dataType="currency" value={getValue()} />
          </Span>
        ),
      },
      {
        id: "NetAmountPercentageInPeriod",
        accessorKey: "NetAmountPercentageInPeriod",
        header: "% of Rev",
        meta: { dataType: "decimal" },
        aggregationFn: "sum",
        aggregatedCell: ({ getValue }: { getValue: () => number }) => (
          <Span style={{ fontWeight: "700", fontStyle: "italic" }}>
            <Format dataType="decimal" value={getValue().toFixed(2)} />
          </Span>
        ),
      },
      {
        id: "QuantitySold",
        accessorKey: "QuantitySold",
        header: "Sales",
        meta: { dataType: "decimal" },
        aggregationFn: "sum",
        aggregatedCell: ({ getValue }: { getValue: () => number }) => (
          <Span style={{ fontWeight: "700", fontStyle: "italic" }}>
            <Format dataType="decimal" value={getValue().toFixed(2)} />
          </Span>
        ),
        cell: ({ row }: { row: any }) => {
          return (
            <Input
              inputMode="numeric"
              style={{ width: "50px" }}
              defaultValue={row.original.QuantitySold}
              onBlur={(e) =>
                handleManualForecastChange({
                  quantitySold: e.target.value,
                  dish: row.original,
                })
              }
            />
          );
        },
      },
      {
        id: "QuantitySoldPercentageInPeriod",
        accessorKey: "QuantitySoldPercentageInPeriod",
        header: "% of Sales",
        meta: { dataType: "decimal" },
        aggregationFn: "sum",
        aggregatedCell: ({ getValue }: { getValue: () => number }) => (
          <Span style={{ fontWeight: "700", fontStyle: "italic" }}>
            <Format dataType="decimal" value={getValue().toFixed(2)} />
          </Span>
        ),
      },
    ],
    [handleManualForecastChange]
  );

  return (
    <DataTable
      columns={columns}
      data={data}
      enableGrouping
      enableExpanding
      height={"46vh"}
      initialState={{
        grouping: ["Level1Menu", "Level2Menu", "Level3Menu"],
        expanded: expanded,
      }}
      state={{
        columnVisibility: {
          DishId: false,
          DishName: true,
          Level1Menu: true,
          Level2Menu: true,
          Level3Menu: true,
          NetAmount: true,
          NetAmountPercentageInPeriod: true,
          QuantitySold: true,
          QuantitySoldPercentageInPeriod: true,
        },
        expanded: expanded,
      }}
      onExpandedChange={setExpanded}
    />
  );
};

export default DishSalesTable;
