import React, { FC } from "react";
import "./dailyWeatherStatistic.css";

type DailyWeatherStatisticProps = {
  metrics: string[];
  title: string;
  icon: React.ReactNode;
};

const DailyWeatherStatistic: FC<DailyWeatherStatisticProps> = ({
  metrics,
  title,
  icon,
}) => {
  const metricComponents = metrics.map((metric, index) => (
    <div key={index}>{metric}</div>
  ));
  return (
    <div className="daily-weather-statistic-container">
      <span>
        {icon} {title}
      </span>
      {metricComponents}
    </div>
  );
};

export default DailyWeatherStatistic;
