import { useCallback, useEffect, useReducer } from "react";
import { AggregatedSales } from "../types/aggregatedSales";
import { createCtx } from "../utilities/contextHelper";
import { createAction } from "../utilities/createReducerAction";
import { PeriodTypes } from "../constants/periodTypes";
import { fetchAggregatedSales } from "../services/aggregatedSalesService";
import {
  adjustMonths,
  getSearchPeriod,
  adjustDateOnPeriodTypeChange,
} from "../utilities/dateHelpers";
import { useUserContext } from "./userContext";
import UrlSearchParams from "../constants/urlSearchParams";
import dayjs from "dayjs";
import { UserSite } from "../types/user";
import { useSearchParamsWithFallback } from "../utilities/useSearchParamsWithFallback";
import {
  parseDateParam,
  parsePeriodTypeParam,
  parseSiteIdParam,
} from "../utilities/urlParamsHelpers";

export type SalesMixState = {
  selectedSite?: UserSite;
  aggregatedSales: AggregatedSales[];
  periodType: PeriodTypes;
  selectedDate: Date;
  searchPeriod: { from: string; to: string };
};

export enum SalesMixStateEvents {
  SITE_CHANGE,
  PERIOD_TYPE_CHANGE,
  DATE_CHANGE,
  SALES_DATA_CHANGE,
}

const siteChangeEvent = (site: UserSite) =>
  createAction(SalesMixStateEvents.SITE_CHANGE, site);
const periodTypeChangeEvent = (periodType: PeriodTypes) =>
  createAction(SalesMixStateEvents.PERIOD_TYPE_CHANGE, periodType);
const dateChangeEvent = (date: Date) =>
  createAction(SalesMixStateEvents.DATE_CHANGE, date);
const salesDataChangeEvent = (salesData: AggregatedSales[]) =>
  createAction(SalesMixStateEvents.SALES_DATA_CHANGE, salesData);

type SiteChangeEvent = ReturnType<typeof siteChangeEvent>;
type PeriodTypeChangeEvent = ReturnType<typeof periodTypeChangeEvent>;
type DateChangeEvent = ReturnType<typeof dateChangeEvent>;
type SalesDataChangeEvent = ReturnType<typeof salesDataChangeEvent>;

export type SalesMixStateActions =
  | SiteChangeEvent
  | PeriodTypeChangeEvent
  | DateChangeEvent
  | SalesDataChangeEvent;

const initiaSalesMixState: SalesMixState = {
  aggregatedSales: [],
  periodType: PeriodTypes.DAILY,
  selectedDate: new Date(),
  searchPeriod: getSearchPeriod(PeriodTypes.DAILY, new Date()),
};

export const salesMixReducer = (
  state: SalesMixState,
  action: SalesMixStateActions
): SalesMixState => {
  switch (action.type) {
    case SalesMixStateEvents.SITE_CHANGE:
      return { ...state, selectedSite: action.payload, aggregatedSales: [] };
    case SalesMixStateEvents.PERIOD_TYPE_CHANGE:
      return {
        ...state,
        periodType: action.payload,
        selectedDate: adjustDateOnPeriodTypeChange(action.payload),
        searchPeriod: getSearchPeriod(action.payload, state.selectedDate!),
        aggregatedSales: [],
      };
    case SalesMixStateEvents.DATE_CHANGE:
      return {
        ...state,
        selectedDate: action.payload,
        searchPeriod: getSearchPeriod(state.periodType, action.payload),
        aggregatedSales: [],
      };
    case SalesMixStateEvents.SALES_DATA_CHANGE:
      return { ...state, aggregatedSales: action.payload };
    default:
      throw new Error(`Unknown action type: ${action}`);
  }
};

type SalesMixProviderProps = {
  children: React.ReactNode;
};

interface SalesMixContextType {
  salesMixState: SalesMixState;
  periodTypeChangeEvent: (periodType: PeriodTypes) => void;
  monthChangeEvent: (offset: number) => void;
  dateChangeEvent: (date: Date) => void;
  siteChangeEvent: (siteId: string) => void;
}

export const [useSalesMixContext, CtxProvider] =
  createCtx<SalesMixContextType>();

export const SalesMixProvider: React.FC<SalesMixProviderProps> = ({
  children,
}) => {
  const { userState } = useUserContext();
  const { params, setSearchParamsifNeeded } = useSearchParamsWithFallback({
    [UrlSearchParams.PERIOD_TYPE]: {
      default: PeriodTypes.DAILY,
      parser: parsePeriodTypeParam,
    },
    [UrlSearchParams.DATE]: {
      default: new Date(Date.now()),
      parser: parseDateParam,
    },
    [UrlSearchParams.SITE_ID]: {
      default: userState.user!.siteAccess[0],
      parser: parseSiteIdParam,
      validation: userState.user!.siteAccess,
    },
  });

  const [salesMixState, dispatchSalesMixState] = useReducer(salesMixReducer, {
    ...initiaSalesMixState,
    periodType: params.periodType,
    selectedDate: params.date,
    selectedSite: params.siteId,
    searchPeriod: getSearchPeriod(params.periodType, params.date),
  });

  const periodTypeChangeEvent = (periodType: PeriodTypes) => {
    dispatchSalesMixState({
      type: SalesMixStateEvents.PERIOD_TYPE_CHANGE,
      payload: periodType,
    });
  };

  const siteChangeEvent = (siteId: string) => {
    dispatchSalesMixState({
      type: SalesMixStateEvents.SITE_CHANGE,
      payload:
        userState.user!.siteAccess.find((s) => s.siteId === siteId) ??
        userState.user!.siteAccess[0],
    });
  };

  const dateChangeEvent = (date: Date) => {
    dispatchSalesMixState({
      type: SalesMixStateEvents.DATE_CHANGE,
      payload: date,
    });
  };

  const monthChangeEvent = (offset: number) => {
    dispatchSalesMixState({
      type: SalesMixStateEvents.DATE_CHANGE,
      payload: adjustMonths(salesMixState.selectedDate!, offset),
    });
  };

  const fetch = useCallback(async () => {
    if (salesMixState.selectedDate && salesMixState.selectedSite) {
      const agg = await fetchAggregatedSales({
        siteId: salesMixState.selectedSite.mdrSiteId,
        searchPeriod: salesMixState.searchPeriod,
      });
      dispatchSalesMixState({
        type: SalesMixStateEvents.SALES_DATA_CHANGE,
        payload: agg,
      });
    }
  }, [
    salesMixState.searchPeriod,
    salesMixState.selectedDate,
    salesMixState.selectedSite,
  ]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  // keep state changes sync'd with URL params
  useEffect(() => {
    setSearchParamsifNeeded({
      [UrlSearchParams.PERIOD_TYPE]: salesMixState.periodType.toString(),
      [UrlSearchParams.DATE]: dayjs(salesMixState.selectedDate).format(
        "YYYY-MM-DD"
      ),
      [UrlSearchParams.SITE_ID]: salesMixState.selectedSite?.siteId || "",
    });
  }, [
    salesMixState.periodType,
    salesMixState.selectedDate,
    salesMixState.selectedSite?.siteId,
    setSearchParamsifNeeded,
  ]);

  return (
    <CtxProvider
      value={{
        salesMixState,
        periodTypeChangeEvent,
        dateChangeEvent,
        monthChangeEvent,
        siteChangeEvent,
      }}
    >
      {children}
    </CtxProvider>
  );
};
