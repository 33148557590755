import {
  SimpleButton,
  Toggle,
  ToggleOption,
} from "@tag/tag-components-react-v4";
import { FC } from "react";
import { ForecastTableType } from "../forecastTable";
import { DeleteFilled, SaveDiskFilled } from "@tag/tag-icons";
import { useForecastContext } from "../../../../../context/forecastContext";
import "./forecastTableFilters.css";

type ForecastTableFiltersProps = {
  value: ForecastTableType;
  onToggleChange: (value: ForecastTableType) => void;
  onSave: (modalVisible: boolean) => void;
  onClear: () => void;
};

const ForecastTableFilters: FC<ForecastTableFiltersProps> = ({
  value,
  onToggleChange,
  onSave,
  onClear,
}) => {
  const { forecastState } = useForecastContext();
  const buttonsDisabled = !forecastState.forecastChangeTracking?.changes.length;
  return (
    <div className="forecast-table-filters-container">
      <div className="forecast-table-toggle">
        <Toggle value={value} onChange={(e) => onToggleChange(e.value!)}>
          <ToggleOption value="dishSales">Dish Sales</ToggleOption>
          <ToggleOption value="productUsage">Product Usage</ToggleOption>
        </Toggle>
      </div>
      <div className="forecast-table-buttons">
        <SimpleButton
          accent="teal"
          endIcon={<SaveDiskFilled accent="white" />}
          disabled={buttonsDisabled}
          onClick={() => onSave(true)}
        >
          Save
        </SimpleButton>
        <SimpleButton
          endIcon={<DeleteFilled accent="teal" />}
          disabled={buttonsDisabled}
          onClick={onClear}
        >
          Clear changes
        </SimpleButton>
      </div>
    </div>
  );
};

export default ForecastTableFilters;
