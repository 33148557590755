import { Pill, Pills, Popover, Tooltip } from "@tag/tag-components-react-v4";
import { CalendarUserLined, CircleInfoFilled, PlusLined } from "@tag/tag-icons";
import { useTheme } from "../../../../../../context/themeContext";
import "./weekDaySiteEvents.css";
import { useForecastContext } from "../../../../../../context/forecastContext";
import dayjs from "dayjs";
import SkeletonLoader from "../../../../../SkeletonLoader";
import ErrorMessage from "../../../../../errorMessage/errorMessage";

type WeekDaySiteEventsProps = {
  day: dayjs.Dayjs;
};
const WeekDaySiteEvents: React.FC<WeekDaySiteEventsProps> = ({ day }) => {
  const { theme } = useTheme();
  const { forecastState } = useForecastContext();

  if (forecastState.siteEvents.loading) {
    return <SkeletonLoader />;
  }

  if (forecastState.siteEvents.error) {
    return <ErrorMessage dataType="Site Events" fontSize="11px" />;
  }

  const siteEvents = forecastState.siteEvents.data.filter(
    (siteEvent) =>
      dayjs(siteEvent.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")
  );

  return (
    <div className="week-day-site-events-container">
      <div className="week-day-site-events-header">
        <Tooltip
          background={theme}
          style={{
            borderRadius: "8px",
            border: "1px solid var(--border-color)",
          }}
          tooltipFor={<PlusLined accent="teal" style={{ cursor: "pointer" }} />}
        >
          <div className="week-day-site-event-popover-content">
            +Add new site event
          </div>
        </Tooltip>
      </div>
      {siteEvents.length > 0 ? (
        siteEvents.map((siteEvent, index) => (
          <div className="week-day-site-events-info-item" key={index}>
            <div className="week-day-site-event-name">
              <span>
                <CalendarUserLined accent="teal" /> {siteEvent.name}
              </span>
            </div>
            <Popover
              popoverFor={
                <div className="week-day-site-events-info">
                  <CircleInfoFilled accent="teal" />
                </div>
              }
              background={theme}
              style={{
                borderRadius: "8px",
                border: "1px solid var(--border-color)",
              }}
            >
              <div className="week-day-site-events-popover-content">
                <div className="week-day-site-events-popover-header">
                  {siteEvent.name}
                </div>
                <div className="week-day-site-events-popover-description">
                  {siteEvent.description}
                </div>
                <div className="week-day-site-events-popover-pills">
                  <Pills>
                    {siteEvent.tags.map((tag, index) => (
                      <Pill key={index}>{tag}</Pill>
                    ))}
                  </Pills>
                </div>
              </div>
            </Popover>
          </div>
        ))
      ) : (
        <div className="week-day-no-site-events-container">
          <span>
            <CalendarUserLined accent="teal" /> {"No site events"}
          </span>
        </div>
      )}
    </div>
  );
};

export default WeekDaySiteEvents;
