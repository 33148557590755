import { Skeleton } from "@tag/tag-components-react-v4";
import { FC } from "react";

const SkeletonLoader: FC = () => {
  return (
    <Skeleton
      style={{
        width: "100%",
        height: "100%",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    />
  );
};

export default SkeletonLoader;
