import React from "react";
import {
  GhostIconButton,
  Toggle,
  ToggleOption,
} from "@tag/tag-components-react-v4";
import Dropdown from "../dropDown/dropdown";
import { PeriodTypes, periodTypes } from "../../constants/periodTypes";
import StyledDatePicker from "../styledDatePicker/styledDatePicker";
import { ArrowLeftThinFilled, ArrowRightThinFilled } from "@tag/tag-icons";
import { adjustDays } from "../../utilities/dateHelpers";
import { UserSite } from "../../types/user";
import "./forecastMixFilters.css";

type ForecastMixFiltersProps = {
  currentDate: Date;
  periodType: PeriodTypes;
  selectedSite?: string;
  sites: UserSite[];
  onDateChange: (date: Date) => void;
  onMonthChange: (offset: number) => void;
  onPeriodChange: (period: PeriodTypes) => void;
  onSiteChange: (siteId: string) => void;
};

const ForecastMixFilters: React.FC<ForecastMixFiltersProps> = ({
  currentDate,
  periodType,
  sites,
  selectedSite,
  onDateChange,
  onMonthChange,
  onPeriodChange,
  onSiteChange,
}) => {
  const periodToggles = periodTypes.map((period) => (
    <ToggleOption key={period.value} value={period.value}>
      {period.label}
    </ToggleOption>
  ));

  return (
    <div className="forecastMix-header-filters">
      <div className="salesMix-header-toggle">
        <Toggle value={periodType} onChange={(e) => onPeriodChange(e.value!)}>
          {periodToggles}
        </Toggle>
      </div>

      {periodType === PeriodTypes.DAILY && (
        <div className="forecastMix-header-date-picker">
          <GhostIconButton
            accent="teal"
            aria-label="back"
            icon={<ArrowLeftThinFilled />}
            onClick={() => onDateChange(adjustDays(currentDate, -1))}
          />
          <StyledDatePicker
            value={currentDate}
            onDateChange={(e) => onDateChange(e.localDate!)}
          />
          <GhostIconButton
            accent="teal"
            aria-label="forward"
            icon={<ArrowRightThinFilled />}
            onClick={() => onDateChange(adjustDays(currentDate, 1))}
          />
        </div>
      )}

      {periodType === PeriodTypes.MONTHLY && (
        <div className="forecastMix-header-month-picker">
          <GhostIconButton
            accent="teal"
            aria-label="back"
            icon={<ArrowLeftThinFilled />}
            onClick={() => onMonthChange(-1)}
          />
          <h5>
            {currentDate.toLocaleDateString("en-GB", {
              month: "short",
              year: "numeric",
            })}
          </h5>
          <GhostIconButton
            accent="teal"
            aria-label="forward"
            icon={<ArrowRightThinFilled />}
            onClick={() => onMonthChange(1)}
          />
        </div>
      )}
      {periodType === PeriodTypes.WEEKLY && (
        <div className="forecastMix-header-week-picker">
          <GhostIconButton
            accent="teal"
            aria-label="back"
            icon={<ArrowLeftThinFilled />}
            onClick={() => onDateChange(adjustDays(currentDate, -7))}
          />
          <h5>
            {` W/C ${currentDate.toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}`}
          </h5>
          <GhostIconButton
            accent="teal"
            aria-label="forward"
            icon={<ArrowRightThinFilled />}
            onClick={() => onDateChange(adjustDays(currentDate, 7))}
          />
        </div>
      )}
      <Dropdown
        value={selectedSite}
        textField="siteName"
        valueField="siteId"
        data={sites}
        onChange={(e) => onSiteChange(e.value!)}
      />
    </div>
  );
};

export default ForecastMixFilters;
