import { FC } from "react";
import { DonutChartFilled } from "@tag/tag-icons";
import ViewHeader from "../../components/viewHeader/viewHeader";
import ForecastMixFilters from "../../components/forecastMixFilters/forecastMixFilters";
import DailySalesMixDashbord from "../../components/dailySalesMixDashboard/dailySalesMixDashboard";
import "./salesMixAnalysis.css";
import { useSalesMixContext } from "../../context/salesMixContext";
import { PeriodTypes } from "../../constants/periodTypes";
import { upperCaseFirstLetter } from "../../utilities/stringHelpers";
import { useUserContext } from "../../context/userContext";

const SalesMixAnalysis: FC = () => {
  const {
    salesMixState,
    dateChangeEvent,
    monthChangeEvent,
    periodTypeChangeEvent,
    siteChangeEvent,
  } = useSalesMixContext();
  const { userState } = useUserContext();
  return (
    <div className="sales-mix-container">
      <ViewHeader
        icon={<DonutChartFilled size="medium" accent="teal" />}
        title={`Sales-Mix Analysis \\ ${upperCaseFirstLetter(
          PeriodTypes[salesMixState.periodType]
        )} \\ ${salesMixState.selectedSite?.siteName}`}
        filters={
          <>
            <ForecastMixFilters
              sites={userState.user!.siteAccess}
              currentDate={salesMixState.selectedDate}
              periodType={salesMixState.periodType}
              selectedSite={salesMixState.selectedSite?.siteId}
              onDateChange={dateChangeEvent}
              onSiteChange={siteChangeEvent}
              onMonthChange={monthChangeEvent}
              onPeriodChange={periodTypeChangeEvent}
            />
          </>
        }
      />
      <DailySalesMixDashbord />
    </div>
  );
};

export default SalesMixAnalysis;
