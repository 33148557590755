import { OrganisationRole } from "../constants/userTypes";
import { ApplicationUser } from "../types/user";

export const dummyMcMullensUser: ApplicationUser = {
  id: "User-e81625b9-8ee1-445b-b029-fef5aa75c54b",
  type: "User",
  pkOrganisationId: "a09db8cd-f6d6-4958-9a1b-1e5b139a2e1b",
  userId: "McMullensAndSons",
  organisationName: "McMullens & Sons",
  fullName: "Evo User",
  active: true,
  lastUpdated: new Date(),
  organisationRoles: [OrganisationRole.ADMIN],
  siteAccess: [
    {
      siteId: "McSite-1",
      roles: OrganisationRole.ADMIN,
      siteName: "Salisbury Arms",
      mdrSiteId: 3014,
      postCode: "SG14 1BZ",
    },
    {
      siteId: "McSite-2",
      roles: OrganisationRole.ADMIN,
      siteName: "Crocodile",
      mdrSiteId: 2995,
      postCode: "EN8 9NQ",
    },
    {
      siteId: "McSite-3",
      roles: OrganisationRole.ADMIN,
      siteName: "Cricketers Woodford",
      mdrSiteId: 2993,
      postCode: "IG8 9HQ",
    },
  ],
};

export const dummyTurtleBayUser: ApplicationUser = {
  id: "User-e81625b9-8ee1-445b-b029-fef5aa75c54b",
  type: "User",
  pkOrganisationId: "a09db8cd-f6d6-4958-9a1b-1e5b139a2e1b",
  userId: "TurtleBay",
  organisationName: "Turtle Bay",
  fullName: "Evo User",
  active: true,
  lastUpdated: new Date(),
  organisationRoles: [OrganisationRole.ADMIN],
  siteAccess: [
    {
      siteId: "TBSite-1",
      roles: OrganisationRole.ADMIN,
      siteName: "Camden",
      mdrSiteId: 12445,
      postCode: "NW1 7BA",
    },
    {
      siteId: "TBSite-2",
      roles: OrganisationRole.ADMIN,
      siteName: "Leicester",
      mdrSiteId: 5810,
      postCode: "LE1 4SA",
    },
    {
      siteId: "TBSite-3",
      roles: OrganisationRole.ADMIN,
      siteName: "Romford",
      mdrSiteId: 11709,
      postCode: "RM1 1AU",
    },
  ],
};

export const dummyFrasersUser: ApplicationUser = {
  id: "User-e81625b9-8ee1-445b-b029-fef5aa75c54b",
  type: "User",
  pkOrganisationId: "a09db8cd-f6d6-4958-9a1b-1e5b139a2e1b",
  userId: "Frasers",
  organisationName: "Frasers Hospitality UK Ltd",
  fullName: "Evo User",
  active: true,
  lastUpdated: new Date(),
  organisationRoles: [OrganisationRole.ADMIN],
  siteAccess: [
    {
      siteId: "FRSite-2",
      roles: OrganisationRole.ADMIN,
      siteName: "Hotel du Vin Edinburgh",
      mdrSiteId: 8317,
      postCode: "EH1 1EZ",
    },
    {
      siteId: "FRSite-3",
      roles: OrganisationRole.ADMIN,
      siteName: "Malmaison Birmingham",
      mdrSiteId: 8332,
      postCode: "B1 1RD",
    },
  ],
};

export const dummyUsers = [
  dummyMcMullensUser,
  dummyTurtleBayUser,
  dummyFrasersUser,
];
