import { FC, useMemo } from "react";
import { Item } from "../../../../types/aggregatesSalesApi";
import { DataTable, Format, P } from "@tag/tag-components-react-v4";
import "./dishSalesTable.css";

type DishSalesTableProps = {
  items: Item[];
};

const DishSalesTable: FC<DishSalesTableProps> = ({ items }) => {
  // had to set this to any[] because the ColumnDataType type is not exported - feels like a bug in Tag
  const columns: any[] = useMemo(
    () => [
      {
        accessorKey: "Mdr.DishName",
        header: "Dish Name",
        meta: { dataType: "string" },
        footer: () => (
          <div style={{ textAlign: "left" }}>
            <P textStyle={{ fontWeight: "bold" }}>Total</P>
          </div>
        ),
      },
      {
        accessorKey: "Mdr.Level1MenuName",
        header: "Level 1 Menu",
        meta: { dataType: "string" },
      },
      {
        accessorKey: "Mdr.Level2MenuName",
        header: "Level 2 Menu",
        meta: { dataType: "string" },
      },
      {
        accessorKey: "Mdr.Level3MenuName",
        header: "Level 3 Menu",
        meta: { dataType: "string" },
      },
      {
        accessorKey: "NetAmount",
        header: "Revenue",
        meta: { dataType: "currency" },
        footer: () => (
          <div style={{ textAlign: "right", fontWeight: "bold" }}>
            <Format
              dataType="currency"
              value={items.reduce((sum, row) => sum + row.NetAmount, 0)}
            />
          </div>
        ),
      },
      {
        accessorKey: "NetAmountPercentageInPeriod",
        header: "% of Revenue",
        meta: { dataType: "decimal" },
      },
      {
        accessorKey: "QuantitySold",
        header: "Sales",
        meta: { dataType: "decimal" },
        footer: () => (
          <div style={{ textAlign: "right", fontWeight: "bold" }}>
            <Format
              dataType="decimal"
              value={items.reduce((sum, row) => sum + row.QuantitySold, 0)}
            />
          </div>
        ),
      },
      {
        accessorKey: "QuantitySoldPercentageInPeriod",
        header: "% of sales",
        meta: { dataType: "decimal" },
      },
    ],
    [items]
  );

  return (
    <div className="dish-sales-table">
      <DataTable
        columns={columns}
        data={items}
        enableSorting
        enableMultiSort={true}
      />
    </div>
  );
};

export default DishSalesTable;
