import dayjs from "dayjs";
import { DishSalesForecast } from "../types/dishSalesForecast";
import { DataLoading } from "../types/dataLoading";

/**
 * Generates an array of DishSalesForecast objects for each day between the specified start and end dates.
 *
 * @param {string} startDate - The start date in string format.
 * @param {string} endDate - The end date in string format.
 * @param {DishSalesForecast} baseObject - The base DishSalesForecast object to be used as a template for each generated forecast.
 * @returns {DishSalesForecast[]} An array of DishSalesForecast objects, each representing a forecast for a single day.
 */
const generateForecasts = (
  startDate: string,
  endDate: string,
  baseObject: DishSalesForecast
): DishSalesForecast[] => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const forecasts: DishSalesForecast[] = [];

  for (
    let d = start;
    d.isBefore(end) || d.isSame(end, "day");
    d = d.add(1, "day")
  ) {
    const newStartTime = d.startOf("day").toDate();
    const newEndTime = d.add(1, "day").startOf("day").toDate();
    const newWeekDay = d.format("dddd"); // Get the day of the week
    const newLastUpated = d.add(-7, "day").toDate();

    const updatedObject: DishSalesForecast = {
      ...baseObject,
      StartTime: newStartTime,
      EndTime: newEndTime,
      WeekDay: newWeekDay,
      LastUpdated: newLastUpated,
    };

    forecasts.push(updatedObject);
  }

  return forecasts;
};

type FetchForecastParams = {
  siteId: string;
  seatchPeriod: { from: string; to: string };
};

// MOCK SERVICE: to be replaced with actual service when the API exists using baseFetch
const fetchForecast = async (
  params: FetchForecastParams
): Promise<DataLoading<DishSalesForecast[]>> => {
  try {
    const response = await fetch("/mcMullensDishSalesForecast.json");
    if (!response.ok) {
      return { data: [], loading: false, error: true };
    }
    const data: DishSalesForecast = await response.json();

    return {
      data: generateForecasts(
        params.seatchPeriod.from,
        params.seatchPeriod.to,
        data
      ),
      loading: false,
      error: false,
    };
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
};

export { fetchForecast };
export type { FetchForecastParams };
