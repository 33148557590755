import { AggregatedSales, calculateMenuStats } from "../types/aggregatedSales";
import { AggregatedSalesApi } from "../types/aggregatesSalesApi";
import { isError } from "../utilities/typeguards";
import { baseFetch } from "./baseFetch";
import { endpoints } from "./endpoints";

type FetchAggregatesSalesParams = {
  siteId: number;
  searchPeriod: { from: string; to: string };
};

const fetchAggregatedSales = async (
  params: FetchAggregatesSalesParams
): Promise<AggregatedSales[]> => {
  const url = `${endpoints.getSalesAggregate}?code=${process.env.REACT_APP_GETSALESAGGREGATE}&regionID=1&purchaserUnitID=${params.siteId}&dateFrom=${params.searchPeriod.from}&dateTo=${params.searchPeriod.to}`;
  const response = await baseFetch<AggregatedSalesApi[]>({
    endpointUrl: url,
    options: { method: "GET" },
  });
  if (isError(response)) {
    // do error handling here - show a toast or something
    throw new Error(`Error fetching aggregated sales data ${response}`);
  }

  return response.map((sale) => {
    return {
      ...sale,
      Level1MenuAggregateStatistics: calculateMenuStats(
        sale.Items,
        "Level1MenuID"
      ),
      Level2MenuAggregateStatistics: calculateMenuStats(
        sale.Items,
        "Level2MenuID"
      ),
      Level3MenuAggregateStatistics: calculateMenuStats(
        sale.Items,
        "Level3MenuID"
      ),
    };
  });
};

export { fetchAggregatedSales };
